import React, { useMemo, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { History } from 'history'
import jwt_decode from "jwt-decode"

import groupState from 'appRecoil/group'
import isAuthenticatedState from 'appRecoil/isAuthenticated'
import jwtState from 'appRecoil/jwt'

import { API, ROUTES, SERVER_URL } from 'utils/constants'
import getFetchOptions from "utils/getFetchOptions"
import type { DebtType, GroupType, MemberType } from 'utils/group'
import handleResponse from 'utils/handleResponse'
import scaleAmount from "utils/scaleAmount"

import DebtDelta from "./DebtDelta"

type Props = {
  group: GroupType,
}



const DebtDeltaContainer = (props: Props) => {
  const { group } = props

  const setGroup = useSetRecoilState(groupState)
  const jwt = useRecoilValue(jwtState)
  const isAuthenticated = useRecoilValue(isAuthenticatedState)

  const [filteredMemberIndex, setFilteredMemberIndex] = useState<number>(-1)

  //returns whether this user is the owner
  const isOwner = useMemo(() => {
    if(isAuthenticated) {
      try {
        return (jwt_decode(jwt) as {_id: string})._id === props.group.userId
      }
      catch(err) {
        console.error(err)
      }
    }

    return false
  }, [isAuthenticated])

  const editGroup = (history: History) => {
    fetch(`${SERVER_URL}${API.EDIT}`, {
      ...getFetchOptions(jwt),
      method: 'PUT',
      body: JSON.stringify({
        _id: props.group._id,
      }),
    }).then(handleResponse).then(
      res => res.json()
    ).then(data => {
      setGroup({ //set the group data in state
        ...props.group,
        ...data,
      })
      history.push(ROUTES.INFO) //go to the info page
    }).catch(err => {
      console.error(err) //TODO
    })
  }

  const deleteGroup = (history: History) => {
    fetch(`${SERVER_URL}${API.GROUP}`, {
      ...getFetchOptions(jwt),
      method: 'DELETE',
      body: JSON.stringify({
        _id: props.group._id,
      }),
    }).then(handleResponse).then(data => {
      history.push(ROUTES.MY_GROUPS)
    }).catch(err => {
      console.error(err) //TODO
    })
  }

  const filterOptions = useMemo(() => [{
    key: -1,
    text: "- All Members -",
    value: -1,
  }].concat(
    group.members.map((m, i) => ({
      key: i,
      text: m.name,
      value: i,
    }))
  ), [group])



  //format text
  const debtCounts = props.group.debtCounts
  let simplifiedText = `${debtCounts.complexCount  } debts`
  let amountText = `$${scaleAmount(debtCounts.complexAmount)} in debts`
  //add simplified if it is meaningful (ie lower than complex)
  if(debtCounts.complexCount > debtCounts.simplifiedCount) {
    simplifiedText += ` simplified to ${debtCounts.simplifiedCount}`
    amountText += ` simplified to $${scaleAmount(debtCounts.simplifiedAmount)}`
  }

  const filteredMember = props.group.members[filteredMemberIndex]
  const filteredMemberText = useMemo(() => {
    let text = ''

    if(filteredMember) {
      const className = filteredMember.totalOwed > 0 ? "moneyRedFont" : "moneyGreenFont"

      const personPeople = filteredMember.debtIndecies.length===1 ? "person" : "people"
      text = `${filteredMember.name} has no debts`
      if(filteredMember.totalOwed > 0) { //member is ower
        text = `${filteredMember.name} owes $${scaleAmount(filteredMember.totalOwed)} to ${filteredMember.debtIndecies.length} ${personPeople}`;
      }
      else if(filteredMember.totalOwed < 0) { //member is a payer
        text = `${filteredMember.name} is owed $${scaleAmount(-1*filteredMember.totalOwed)} by ${filteredMember.debtIndecies.length} ${personPeople}`;
      }
    }

    return text
  }, [filteredMember])

  const filteredDebts = (
    filteredMemberIndex === -1
    ? props.group.debts
    : (
      props.group.members[filteredMemberIndex].debtIndecies.map(debtIndex =>
        props.group.debts[debtIndex]
      )
    )
  )

  return (
    <DebtDelta
      amountText={amountText}
      deleteGroup={deleteGroup}
      editGroup={editGroup}
      filteredDebts={filteredDebts}
      filteredMember={filteredMember}
      filteredMemberIndex={filteredMemberIndex}
      filteredMemberText={filteredMemberText}
      filterOptions={filterOptions}
      group={group}
      isOwner={isOwner}
      setFilteredMemberIndex={setFilteredMemberIndex}
      simplifiedText={simplifiedText}
    />
  )
}

export default DebtDeltaContainer


export type DebtDeltaProps = {
  amountText: string,
  deleteGroup: (history: History) => void,
  editGroup: (history: History) => void,
  filteredDebts: DebtType[],
  filteredMember?: MemberType,
  filteredMemberIndex: number,
  filteredMemberText: string,
  filterOptions: {
    key: number, text: string, value: number,
  }[],
  group: GroupType,
  isOwner: boolean,
  setFilteredMemberIndex: (index: number) => void,
  simplifiedText: string,
}

import React, { useCallback, useMemo, useReducer  } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Message } from 'semantic-ui-react'

import groupState from 'appRecoil/group'
import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import BottomBar from "Components/BottomBar/BottomBar"
import ScrollForm, { ScrollFormContext, ScrollFormQuestionContextType, withScrollFormQuestionContext } from "Components/ScrollForm/ScrollForm"
import ScrollFormBottomBar from "Components/ScrollForm/ScrollFormBottomBar"
import ScrollFormInput from "Components/ScrollForm/ScrollFormInput"
import ScrollFormOkButton from "Components/ScrollForm/ScrollFormOkButton"

import binaryInsert from 'utils/binaryInsert'
import binarySearch from 'utils/binarySearch'
import scaleAmount from "utils/scaleAmount"
import parseInputAmount, { AMOUNT_FACTOR } from 'utils/parseInputAmount'
import { DEFAULT_PAYER } from 'utils/group'
import { INPUT_RESTRICTIONS } from 'utils/constants'
import type { PayerType } from 'utils/group'
import pushElementToArrayCopy from 'utils/pushElementToArrayCopy'

import "./addPayerForm.scss"

type PayerActionType =
| { type: 'description', description: string }
| { type: 'inputAmount', inputAmount: string }
| { type: 'name', name: string }
| { type: 'reset' }

const initPayer = () => JSON.parse(JSON.stringify(DEFAULT_PAYER))

export function payerReducer(
  payer: PayerType,
  action: PayerActionType,
):PayerType {
  switch (action.type) {
    case 'description':
      return {...payer, description: action.description}
    case 'inputAmount':
      return {...payer, amount: parseInputAmount(action.inputAmount)}
    case 'name':
      return {...payer, name: action.name}
    case 'reset':
      return initPayer()
    default:
      throw new Error(`Unexpected action`)
  }
}


type Props = {
  hide: Function,
  isShown: boolean,
  triggerReset: boolean,
}

const AddPayerForm = (props: Props) => {
  const group = useRecoilValue(groupState)
  const [transaction, setTransaction] = useRecoilState(transactionState)

  const [payer, dispatchPayer] = useReducer(payerReducer, initPayer())

  const submit = (resetForm:Function) => {
    const submitPayer = {...payer, description: payer.description.trim()}
    dispatchTransaction(transaction, setTransaction, { type: 'addPayer', payer: submitPayer })
    resetForm()
    props.hide()
    dispatchPayer({type: "reset"})
  }

  const remainingDebt = transaction.totalOwed - transaction.totalPaid

  const questions = useMemo(() => {
    return [
      {
        canGoToNextQuestion: payer.description.trim().length > 0,
        element: (
          <ScrollFormInput
            errorMessage="Please enter a description"
            isShown={props.isShown}
            label="What was paid for?"
            maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => dispatchPayer({type: "description", description: e.target.value})}
            placeholder={"Ex: Tacos"}
            required
            subHeading={
              <div>
                <button className="ui button greenButton" onClick={e => dispatchPayer({type: "description", description: "Everything"})}>
                  Everything
                </button>
                <br/><br/>
              </div>
            }
            type="text"
            value={payer.description}
          />
        )
      },
      {
        canGoToNextQuestion: group.members.find(m => m.name===payer.name) !== undefined,
        element: (
          <ScrollFormContext.Consumer>
            {(scrollFormContext) => (
              <div className="ui form">
                <div className="required field"><label>Who paid?</label></div>
                <br/>
                <div>
                  {group.members.map((member, memberIndex) => {
                    const isSelected = payer.name === member.name

                    return (
                      <button
                        key={memberIndex}
                        className={`ui button ${isSelected?"greenButton":"basic"}`}
                        onClick={e => dispatchPayer({type: 'name', name: member.name })}
                      >
                        {member.name}
                      </button>
                    )
                  })}
                </div>
                <br/>
                <div style={{opacity: scrollFormContext.canGoToNextQuestion?1:0}}>
                  <ScrollFormOkButton/>
                </div>
              </div>
            )}
          </ScrollFormContext.Consumer>
        )
      },
      {
        canGoToNextQuestion: payer.amount > 0,
        element: (
          <ScrollFormInput
            errorMessage="Please enter an amount"
            isShown={props.isShown}
            label="How much was paid?"
            min={0}
            okMessage={
              <ScrollFormContext.Consumer>
                {(scrollFormContext) => (
                  <React.Fragment>
                    <button className="ui button" onClick={e => submit(scrollFormContext.resetForm)}>Submit</button> <span>press Enter</span>
                  </React.Fragment>
                )}
              </ScrollFormContext.Consumer>
            }
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => dispatchPayer({type: "inputAmount", inputAmount: e.target.value})}
            required
            subHeading={
              <div>
                <button className="ui button greenButton" onClick={e => dispatchPayer({type: "inputAmount", inputAmount: (remainingDebt/AMOUNT_FACTOR).toString()})}>
                  Remaining Debt: {scaleAmount(remainingDebt)}
                </button>
                <br/><br/>
              </div>
            }
            type="number"
            value={payer.amount/AMOUNT_FACTOR || ""}
          />
          //TODO everything button
        )
      },
    ]
  }, [payer, props.isShown])



  const goToQuestionCallback = (questionIndex: number, validQuestionIndex: boolean, resetForm: Function) => {
    if(questionIndex === questions.length) {
      submit(resetForm)
    }
  }

  return (
    <div className="fullscreen addPayerForm">
      <h3 className="fullscreenHeader">Add Payer</h3>

      <ScrollForm
        goToQuestionCallback={goToQuestionCallback}
        questions={questions}
        triggerReset={props.triggerReset}
      >
        <ScrollFormBottomBar
          back={props.hide}
          numQuestions={questions.length}
        />
      </ScrollForm>
    </div>
  )
}

export default AddPayerForm

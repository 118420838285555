import React, { useEffect, useState } from 'react'

import LoadingSpinner from "Components/LoadingSpinner/LoadingSpinner"

import "./waitForServer.scss"

export default function WaitForServer() {
  return (
    <div id="waitForServer" className="fadeIn fullPage">
      <div className="ui container">
        <h2>Waking up server...</h2>
        <LoadingSpinner dark/>
        <p>As a free service, DebtDelta uses free-tier cloud hosting. This should take less than 30 seconds. Thank you for your patience!</p>
      </div>
    </div>
  )
}

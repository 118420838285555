import React from 'react'

import "./serverFailed.scss"

export default function ServerFailed() {
  return (
    <div id="serverFailed" className="fullPage">
      <div>
        <h4>Contacting the server failed.</h4>
        <h4>Please try again later :(</h4>
      </div>
    </div>
  )
}

import React, { useMemo, useState } from "react"
import { useRecoilValue } from 'recoil'

import jwtState from 'appRecoil/jwt'

import LoadingSpinner from "Components/LoadingSpinner/LoadingSpinner"

import { API, SERVER_URL } from 'utils/constants'
import getFetchOptions from "utils/getFetchOptions"
import type { GroupType } from 'utils/group'
import handleResponse from 'utils/handleResponse'

import AccessCodeFormContainer from "./AccessCodeForm/AccessCodeFormContainer"
import DebtDeltaContainer from "./DebtDelta/DebtDeltaContainer"
import View from "./View"

//to use data from a file, modify then uncomment the import statement below
//then comment out the useMemo request
// import data from "data/beachHouseZeroMutualDebt.json"

const ViewContainer = (props: {
  useParams: () => {url: string},
}) => {
  const url = props.useParams().url

  const jwt = useRecoilValue(jwtState)

  const [group, setGroup] = useState<GroupType | null>(null)
  const [status, setStatus] = useState<string>("")

  useMemo(async () => {
    setStatus("loading")
    try {
      const res = await fetch(`${SERVER_URL}${API.VIEW}/${url}`, {
        ...getFetchOptions(jwt),
        method: 'GET',
      })

      if(res.status === 401) { //if this group requires an access code
        setStatus("accessCode")
      }
      else if(res.status === 200) { //else if we got the group data
        const data = await res.json()
        setGroup(data)
        setStatus("")
        // console.log("DONE", data)
      }
      else { //else there is some other error
        await handleResponse(res)
      }
    }
    catch(err) {
      console.error(err)
      setStatus(err.message)
    }
  }, [])

  if(group) {
    return <DebtDeltaContainer group={group}/>
  }
  else if(status === "accessCode") {
    return <AccessCodeFormContainer setGroup={setGroup}/>
  }
  return <View status={status}/>
}

export default ViewContainer

export type ViewProps = {
  status: string,
}

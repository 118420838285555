import React from 'react'

import type { InputDataType } from "utils/inputData"

type Props = {
  data: InputDataType,
}

export default function Input(props: Props) {
  const {
    data: {
      error,
      label,
      placeholder,
      required,
      rest,
      set,
      value,
    }
  } = props

  return (
    <div className={`field ${error ? "error":""} ${required?"required":""}`}>
      <label>{label}</label>
      <input
        {...rest}
        onChange={e => set(e.target.value)}
        placeholder={placeholder}
        value={value}
      />
    </div>
  )
}

import React, { useMemo, useState } from 'react'
import { Message } from 'semantic-ui-react'
import { useParams } from "react-router-dom"

import Input from "Components/Input/Input"

import { INPUT_RESTRICTIONS } from 'utils/constants'

import type { AccessCodeFormProps } from "./AccessCodeFormContainer"

const AccessCodeForm = (props: AccessCodeFormProps) => {
  const {
    inputData,
    status,
    submit,
  } = props

  const { url } = useParams<{url: string}>()

  const message = useMemo(() => {
    if(status === "loading") {
      return <Message header='Loading...'/>
    }
    else if(status) {
      return <Message negative header={status}/>
    }
  }, [status])


  return (
    <div className="ui container">
      <br/>
      <form className="ui form" onSubmit={e => {
        e.preventDefault()
        submit(url)
      }}>
        <h2 className="ui dividing header">Access code to view {url}</h2>

        {inputData.map((d,i) => {
          <Input key={i} data={d}/>
        })}

        <button className="ui submit right labeled icon button greenButton" type="submit">
          Submit
          <i className="arrow right icon"></i>
        </button>

        {message}
      </form>
    </div>
  )
}

export default AccessCodeForm

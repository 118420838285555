import scaleAmount from "utils/scaleAmount"
import type { GroupType, StepType } from 'utils/group'
import objFieldCmp from 'utils/objFieldCmp'


//colors
const DARK_DARK_DARK_RED = "#CB4335"
const DARK_DARK_RED = "#E74C3C"
const DARK_RED = "#EC7063"
const RED = "#F1948A"
const GREEN = "#58D68D"
const DARK_GREEN = "#28B463"
const DARK_DARK_GREEN = "#239B56"

const REDS = [RED, DARK_RED, DARK_DARK_RED, DARK_DARK_DARK_RED]
const GREENS = [GREEN, DARK_GREEN, DARK_DARK_GREEN]



export type CircleType = {
  cx: number,
  cy: number,
  text: string,
  x: number,
  y: number,
}

export type LineType = {
  stroke: string,
  strokeWidth: number,
  value: number,
  x1: number,
  x2: number,
  y1: number,
  y2: number,
}

export type TextType = {
  text: string,
  transform?: string,
  x: number,
  y: number,
}




type DrawParametersType = {
  circleRadius: number,
  height: number,
  index: number,
  names: string[],
  parameters: number[],
  steps: StepType[],
  strokeWidth: number,
  textPadding: number,
  width: number,
}

type HeadingType = {
  circles: CircleType[],
  easyText: {
    line1: string,
    line2: string,
    main: string,
  },
  lines: LineType[],
  texts: TextType[],
  title: string,
}

export type DrawOutputType = {
  before: HeadingType,
  after: HeadingType,
}


const stepDrawFunctions:{
  [key:string]: (params: DrawParametersType) => DrawOutputType,
} = {
  mutualDebt: (params: DrawParametersType) => {
    const {
      circleRadius,
      height,
      index,
      names,
      parameters,
      steps,
      strokeWidth,
      textPadding,
      width,
    } = params

    const stepArray = steps[index].array
    const nextStepArray = steps[index+1].array

    const leftCircle = { x:0.25*width, y:0.5*height }
    const rightCircle = { x:0.75*width, y:0.5*height }

    const larger = parameters[0]
    const smaller = parameters[1]

    const beforeTitle = `${names[larger]} and ${names[smaller]} owe each other`
    let afterTitle = ""
    //if their debt is non zero
    if(nextStepArray[larger][smaller] > 0) {
      afterTitle = `${names[larger]} owes ${names[smaller]} a net $${scaleAmount(nextStepArray[larger][smaller])}`
    }
    else {
      afterTitle = "Their debt cancels out"
    }


    //lines between circles
    const lineStrokeWidth = strokeWidth+circleRadius*2
    const beforeLines = [
      {x1:leftCircle.x,x2:rightCircle.x,y1:leftCircle.y+lineStrokeWidth/4,y2:rightCircle.y+lineStrokeWidth/4,stroke:"",strokeWidth:lineStrokeWidth/2, value:stepArray[smaller][larger]},
      {x1:leftCircle.x,x2:rightCircle.x,y1:leftCircle.y-lineStrokeWidth/4,y2:rightCircle.y-lineStrokeWidth/4,stroke:"",strokeWidth:lineStrokeWidth/2, value:stepArray[larger][smaller]},
    ];
    const afterLines = []
    //only draw if the amount is greater than 0
    if(nextStepArray[larger][smaller] > 0) {
      afterLines.push({x1:leftCircle.x,x2:rightCircle.x,y1:leftCircle.y,y2:rightCircle.y,stroke:"", strokeWidth:lineStrokeWidth, value: nextStepArray[larger][smaller]});
    }
    processLines(beforeLines, REDS, 1)
    processLines(afterLines, GREENS, 1)

    //line text
    const beforeText = [
      {x:width/2,y:height/2+circleRadius/2,text:`<<< $${scaleAmount(stepArray[smaller][larger])}`,},
      {x:width/2,y:height/2-circleRadius/2,text:`$${scaleAmount(stepArray[larger][smaller])} >>>`,},
    ]
    const afterText = []
    //only draw if the amount is greater than 0
    if(nextStepArray[larger][smaller] > 0) {
      afterText.push({x:width/2,y:height/2,text:`$${scaleAmount(nextStepArray[larger][smaller])} >>>`,});
    }


    const beforeCircles = [
      {cx:leftCircle.x,cy:leftCircle.y,text:names[larger],x:leftCircle.x,y:leftCircle.y-circleRadius-textPadding},
      {cx:rightCircle.x,cy:rightCircle.y,text:names[smaller],x:rightCircle.x,y:rightCircle.y+circleRadius+2*textPadding},
    ]
    const afterCircles = [
      {cx:leftCircle.x,cy:leftCircle.y,text:names[larger],x:leftCircle.x,y:leftCircle.y-circleRadius-textPadding},
      {cx:rightCircle.x,cy:rightCircle.y,text:names[smaller],x:rightCircle.x,y:rightCircle.y+circleRadius+2*textPadding},
    ]

    return {
      before: {
        circles: beforeCircles,
        easyText:{line1:"", line2:"", main:""},
        lines: beforeLines,
        texts: beforeText,
        title: beforeTitle,
      },
      after: {
        circles: afterCircles,
        easyText:{line1:"", line2:"", main:""},
        lines: afterLines,
        texts: afterText,
        title: afterTitle,
      },
    }
  },

  //A owes B more than B owes C
  triangleADebt: (params: DrawParametersType) => {
    const {
      circleRadius,
      height,
      index,
      names,
      parameters,
      steps,
      strokeWidth,
      textPadding,
      width,
    } = params

    const stepArray = steps[index].array
    const nextStepArray = steps[index+1].array


    const topCircle = { x:0.5*width, y:0.25*height }
    const leftCircle = { x:0.3*width, y:0.75*height }
    const rightCircle = { x:0.7*width, y:0.75*height }

    const a = parameters[0] //top
    const b = parameters[1] //left
    const c = parameters[2] //right

    //change before and after titles
    const beforeTitle = `${names[a]} owes $${ scaleAmount(stepArray[a][b])
    } to ${names[b]}, who also owes $${  scaleAmount(stepArray[b][c])
    } to ${names[c]}`;
    const afterTitle = "The debt triangle is simplified";


    //draw triangle lines
    const lineStrokeWidth = strokeWidth+circleRadius*2
    const beforeLines = [
      {x1:topCircle.x,x2:leftCircle.x,y1:topCircle.y,y2:leftCircle.y, stroke:"", strokeWidth: 0, value:stepArray[a][b]},
      {x1:rightCircle.x,x2:leftCircle.x,y1:rightCircle.y,y2:leftCircle.y, stroke:"", strokeWidth: 0, value:stepArray[b][c]}
    ]
    const afterLines:LineType[] = []
    if(nextStepArray[a][b] > 0) afterLines.push({x1:topCircle.x,x2:leftCircle.x,y1:topCircle.y,y2:leftCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[a][b]})
    // if(nextStepArray[b][c] > 0) afterLines.push({x1:rightCircle.x,x2:leftCircle.x,y1:rightCircle.y,y2:leftCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[b][c]}) //this is the B to C leg that shouldn't need to be displayed
    if(nextStepArray[a][c] > 0) afterLines.push({x1:rightCircle.x,x2:topCircle.x,y1:rightCircle.y,y2:topCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[a][c]})
      
    //draw RED line from A to C only if it is greater than 0
    if(stepArray[a][c] > 0) {
      beforeLines.push({x1:rightCircle.x,x2:topCircle.x,y1:rightCircle.y,y2:topCircle.y, stroke:"", strokeWidth: 0, value:stepArray[a][c]});
    }
    processLines(beforeLines, REDS, 0, lineStrokeWidth)
    processLines(afterLines, GREENS, 0, lineStrokeWidth)


    //label lines with debt and direction
    const beforeText = [
      {x:(topCircle.x+leftCircle.x)/2,y:(topCircle.y+leftCircle.y)/2,text:`<<< $${scaleAmount(stepArray[a][b])}`, transform:`rotate(-60,${(leftCircle.x+topCircle.x)/2},${(leftCircle.y+topCircle.y)/2})`},
      {x:(rightCircle.x+leftCircle.x)/2,y:(rightCircle.y+leftCircle.y)/2,text:`$${scaleAmount(stepArray[b][c])} >>>`, transform:""}
    ]
    const afterText = [
      {x:(topCircle.x+leftCircle.x)/2,y:(topCircle.y+leftCircle.y)/2,text:`<<< $${scaleAmount(nextStepArray[a][b])}`, transform:`rotate(-60,${(leftCircle.x+topCircle.x)/2},${(leftCircle.y+topCircle.y)/2})`},
      // {x:(rightCircle.x+leftCircle.x)/2,y:(rightCircle.y+leftCircle.y)/2,text:`$${scaleAmount(nextStepArray[b][c])} >>>`, transform:""}, //this is the B to C leg that shouldn't need to be displayed
      {x:(topCircle.x+rightCircle.x)/2,y:(topCircle.y+rightCircle.y)/2,text:`$${scaleAmount(nextStepArray[a][c])} >>>`, transform:`rotate(60,${(rightCircle.x+topCircle.x)/2},${(rightCircle.y+topCircle.y)/2})`},
    ]
    //label RED A C line only if it is above 0
    if(stepArray[a][c] > 0) {
      beforeText.push({x:(topCircle.x+rightCircle.x)/2,y:(topCircle.y+rightCircle.y)/2,text:`$${scaleAmount(stepArray[a][c])} >>>`,
      transform:`rotate(60,${(rightCircle.x+topCircle.x)/2},${(rightCircle.y+topCircle.y)/2})`});
    }


    //draw triangle circle nodes
    const beforeCircles = [
      {cx:leftCircle.x,cy:leftCircle.y,text:names[b],x:leftCircle.x,y:leftCircle.y+circleRadius+2*textPadding,textAnchor:"end"},
      {cx:topCircle.x,cy:topCircle.y,text:names[a],x:topCircle.x,y:topCircle.y-circleRadius - textPadding,textAnchor:"middle"},
      {cx:rightCircle.x,cy:rightCircle.y,text:names[c],x:rightCircle.x,y:rightCircle.y+circleRadius+2*textPadding,textAnchor:"start"},
    ]
    const afterCircles = [
      {cx:leftCircle.x,cy:leftCircle.y,text:names[b],x:leftCircle.x,y:leftCircle.y + circleRadius + 2*textPadding,textAnchor:"end"},
      {cx:topCircle.x,cy:topCircle.y,text:names[a],x:topCircle.x,y:topCircle.y - circleRadius - textPadding,textAnchor:"middle"},
      {cx:rightCircle.x,cy:rightCircle.y,text:names[c],x:rightCircle.x,y:rightCircle.y + circleRadius + 2*textPadding,textAnchor:"start"},
    ]

    return {
      before: {
        circles: beforeCircles,
        easyText:{line1:"", line2:"", main:""},
        lines: beforeLines,
        texts: beforeText,
        title: beforeTitle,
      },
      after: {
        circles: afterCircles,
        easyText:{line1:"", line2:"", main:""},
        lines: afterLines,
        texts: afterText,
        title: afterTitle,
      },
    }
  },
  //A owes B less than B owes C
  triangleVDebt: (params: DrawParametersType) => {
    const {
      circleRadius,
      height,
      index,
      names,
      parameters,
      steps,
      strokeWidth,
      textPadding,
      width,
    } = params

    const stepArray = steps[index].array
    const nextStepArray = steps[index+1].array


    const topCircle = { x:0.5*width, y:0.25*height }
    const leftCircle = { x:0.3*width, y:0.75*height }
    const rightCircle = { x:0.7*width, y:0.75*height }

    const a = parameters[0] //top
    const b = parameters[1] //left
    const c = parameters[2] //right

    //change before and after titles
    let beforeTitle = `${names[a]} owes $${ scaleAmount(stepArray[a][b])
    } to ${names[b]}, who also owes $${  scaleAmount(stepArray[b][c])
    } to ${names[c]}`
    const afterTitle = "The debt triangle is simplified"


    //draw triangle lines
    const lineStrokeWidth = strokeWidth+circleRadius*2
    const beforeLines = [
      {x1:topCircle.x,x2:leftCircle.x,y1:topCircle.y,y2:leftCircle.y, stroke:"", strokeWidth: 0, value:stepArray[a][b]},
      {x1:rightCircle.x,x2:leftCircle.x,y1:rightCircle.y,y2:leftCircle.y, stroke:"", strokeWidth: 0, value:stepArray[b][c]}
    ]
    const afterLines:LineType[] = []
    // if(nextStepArray[a][b] > 0) afterLines.push({x1:topCircle.x,x2:leftCircle.x,y1:topCircle.y,y2:leftCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[a][b]}) //this is the A to B leg that shouldn't need to be displayed
    if(nextStepArray[b][c] > 0) afterLines.push({x1:rightCircle.x,x2:leftCircle.x,y1:rightCircle.y,y2:leftCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[b][c]}) 
    if(nextStepArray[a][c] > 0) afterLines.push({x1:rightCircle.x,x2:topCircle.x,y1:rightCircle.y,y2:topCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[a][c]})
    //draw RED line from A to C only if it is greater than 0
    if(stepArray[a][c] > 0) {
      beforeLines.push({x1:rightCircle.x,x2:topCircle.x,y1:rightCircle.y,y2:topCircle.y, stroke:"", strokeWidth: 0, value:stepArray[a][c]});
    }
    processLines(beforeLines, REDS, 0, lineStrokeWidth)
    processLines(afterLines, GREENS, 0, lineStrokeWidth)


    //label lines with debt and direction
    const beforeText = [
      {x:(topCircle.x+leftCircle.x)/2,y:(topCircle.y+leftCircle.y)/2,text:`<<< $${scaleAmount(stepArray[a][b])}`, transform:`rotate(-60,${(leftCircle.x+topCircle.x)/2},${(leftCircle.y+topCircle.y)/2})`},
      {x:(rightCircle.x+leftCircle.x)/2,y:(rightCircle.y+leftCircle.y)/2,text:`$${scaleAmount(stepArray[b][c])} >>>`, transform:""}
    ]
    const afterText = [
      // {x:(topCircle.x+leftCircle.x)/2,y:(topCircle.y+leftCircle.y)/2,text:`<<< $${scaleAmount(nextStepArray[a][b])}`, transform:`rotate(-60,${(leftCircle.x+topCircle.x)/2},${(leftCircle.y+topCircle.y)/2})`}, //this is the A to B leg that shouldn't need to be displayed
      {x:(rightCircle.x+leftCircle.x)/2,y:(rightCircle.y+leftCircle.y)/2,text:`$${scaleAmount(nextStepArray[b][c])} >>>`, transform:""}, 
      {x:(topCircle.x+rightCircle.x)/2,y:(topCircle.y+rightCircle.y)/2,text:`$${scaleAmount(nextStepArray[a][c])} >>>`, transform:`rotate(60,${(rightCircle.x+topCircle.x)/2},${(rightCircle.y+topCircle.y)/2})`},
    ]
    //label RED A C line only if it is above 0
    if(stepArray[a][c] > 0) {
      beforeText.push({
        x:(topCircle.x+rightCircle.x)/2,y:(topCircle.y+rightCircle.y)/2,
        text:`$${scaleAmount(stepArray[a][c])} >>>`,
        transform:`rotate(60,${(rightCircle.x+topCircle.x)/2},${(rightCircle.y+topCircle.y)/2})`
      })
    }


    //draw triangle circle nodes
    const beforeCircles = [
      {cx:leftCircle.x,cy:leftCircle.y,text:names[b],x:leftCircle.x,y:leftCircle.y + circleRadius + 2*textPadding,textAnchor:"end"},
      {cx:topCircle.x,cy:topCircle.y,text:names[a],x:topCircle.x,y:topCircle.y - circleRadius - textPadding,textAnchor:"middle"},
      {cx:rightCircle.x,cy:rightCircle.y,text:names[c],x:rightCircle.x,y:rightCircle.y + circleRadius + 2*textPadding,textAnchor:"start"},
    ]
    const afterCircles = [
      {cx:leftCircle.x,cy:leftCircle.y,text:names[b],x:leftCircle.x,y:leftCircle.y + circleRadius + 2*textPadding,textAnchor:"end"},
      {cx:topCircle.x,cy:topCircle.y,text:names[a],x:topCircle.x,y:topCircle.y - circleRadius - textPadding,textAnchor:"middle"},
      {cx:rightCircle.x,cy:rightCircle.y,text:names[c],x:rightCircle.x,y:rightCircle.y + circleRadius + 2*textPadding,textAnchor:"start"},
    ]


    return {
      before: {
        circles: beforeCircles,
        easyText:{line1:"", line2:"", main:""},
        lines: beforeLines,
        texts: beforeText,
        title: beforeTitle,
      },
      after: {
        circles: afterCircles,
        easyText:{line1:"", line2:"", main:""},
        lines: afterLines,
        texts: afterText,
        title: afterTitle,
      },
    }
  },

  diamondDebt: (params: DrawParametersType) => {
    const {
      circleRadius,
      height,
      index,
      names,
      parameters,
      steps,
      strokeWidth,
      textPadding,
      width,
    } = params

    const stepArray = steps[index].array
    const nextStepArray = steps[index+1].array


    const leftCircle = { x:0.2*width, y:0.5*height }
    const rightCircle = { x:0.8*width, y:0.5*height }
    const topCircle = { x:0.5*width, y:0.25*height }
    const bottomCircle = { x:0.5*width, y:0.75*height }

    const a = parameters[0]
    const b = parameters[1]
    const c = parameters[2]
    const d = parameters[3]

    //get before and after titles
    const beforeTitle = `${names[a]} and ${names[b]} owe ${names[c]} and ${names[d]}`
    const afterTitle = "The debt diamond is simplified"

    //draw diamond lines
    const lineStrokeWidth = strokeWidth+circleRadius*2
    const beforeLines = [
      {x1:leftCircle.x,x2:topCircle.x,y1:leftCircle.y,y2:topCircle.y, stroke:"", strokeWidth: 0, value:stepArray[a][c]},
      {x1:leftCircle.x,x2:bottomCircle.x,y1:leftCircle.y,y2:bottomCircle.y, stroke:"", strokeWidth: 0, value:stepArray[a][d]},
      {x1:rightCircle.x,x2:topCircle.x,y1:rightCircle.y,y2:topCircle.y, stroke:"", strokeWidth: 0, value:stepArray[b][c]},
      {x1:rightCircle.x,x2:bottomCircle.x,y1:rightCircle.y,y2:bottomCircle.y, stroke:"", strokeWidth: 0, value:stepArray[b][d]},
      //dynamically add after lines
    ]
    const afterLines = []
    if(nextStepArray[a][c] > 0) { afterLines.push( {x1:leftCircle.x,x2:topCircle.x,y1:leftCircle.y,y2:topCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[a][c]} ); } //if there is an amount, draw AC
    if(nextStepArray[a][d] > 0) { afterLines.push( {x1:leftCircle.x,x2:bottomCircle.x,y1:leftCircle.y,y2:bottomCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[a][d]} ); } //if there is an amount, draw AD
    if(nextStepArray[b][c] > 0) { afterLines.push( {x1:rightCircle.x,x2:topCircle.x,y1:rightCircle.y,y2:topCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[b][c]} ); } //if there is an amount, draw BC
    if(nextStepArray[b][d] > 0) { afterLines.push( {x1:rightCircle.x,x2:bottomCircle.x,y1:rightCircle.y,y2:bottomCircle.y, stroke:"", strokeWidth: 0, value:nextStepArray[b][d]} ); } //if there is an amount, draw BD
    processLines(beforeLines, REDS, 0, lineStrokeWidth)
    processLines(afterLines, GREENS, 0, lineStrokeWidth)


    //label lines with debt and direction
    const beforeText = [
      {x:(leftCircle.x+topCircle.x)/2,y:(leftCircle.y+topCircle.y)/2,text:`$${scaleAmount(stepArray[a][c])} >>>`,
      transform:`rotate(-30,${(leftCircle.x+topCircle.x)/2},${(leftCircle.y+topCircle.y)/2})`},
      {x:(leftCircle.x+bottomCircle.x)/2,y:(leftCircle.y+bottomCircle.y)/2,text:`$${scaleAmount(stepArray[a][d])} >>>`,
      transform:`rotate(30,${(leftCircle.x+bottomCircle.x)/2},${(leftCircle.y+bottomCircle.y)/2})`},
      {x:(rightCircle.x+topCircle.x)/2,y:(rightCircle.y+topCircle.y)/2,text:`<<< $${scaleAmount(stepArray[b][c])}`,
      transform:`rotate(30,${(rightCircle.x+topCircle.x)/2},${(rightCircle.y+topCircle.y)/2})`},
      {x:(rightCircle.x+bottomCircle.x)/2,y:(rightCircle.y+bottomCircle.y)/2,text:`<<< $${scaleAmount(stepArray[b][d])}`,
      transform:`rotate(-30,${(rightCircle.x+bottomCircle.x)/2},${(rightCircle.y+bottomCircle.y)/2})`},
      //dynamically add after text
    ]
    const afterText = []
    if(nextStepArray[a][c] > 0) {
      afterText.push({x:(leftCircle.x+topCircle.x)/2,y:(leftCircle.y+topCircle.y)/2,text:`$${scaleAmount(nextStepArray[a][c])} >>>`,
      transform:`rotate(-30,${(leftCircle.x+topCircle.x)/2},${(leftCircle.y+topCircle.y)/2})`}); //AC
    }
    if(nextStepArray[a][d] > 0) {
      afterText.push({x:(leftCircle.x+bottomCircle.x)/2,y:(leftCircle.y+bottomCircle.y)/2,text:`$${scaleAmount(nextStepArray[a][d])} >>>`,
      transform:`rotate(30,${(leftCircle.x+bottomCircle.x)/2},${(leftCircle.y+bottomCircle.y)/2})`}); //AD
    }
    if(nextStepArray[b][c] > 0) {
      afterText.push({x:(rightCircle.x+topCircle.x)/2,y:(rightCircle.y+topCircle.y)/2,text:`<<< $${scaleAmount(nextStepArray[b][c])}`,
      transform:`rotate(30,${(rightCircle.x+topCircle.x)/2},${(rightCircle.y+topCircle.y)/2})`}); //BC
    }
    if(nextStepArray[b][d] > 0) {
      afterText.push({x:(rightCircle.x+bottomCircle.x)/2,y:(rightCircle.y+bottomCircle.y)/2,text:`<<< $${scaleAmount(nextStepArray[b][d])}`,
      transform:`rotate(-30,${(rightCircle.x+bottomCircle.x)/2},${(rightCircle.y+bottomCircle.y)/2})`}); //BD
    }

    //draw triangle circle nodes
    const beforeCircles = [
      {cx:leftCircle.x,cy:leftCircle.y,text:names[a],x:leftCircle.x,y:leftCircle.y+circleRadius+2*textPadding,textAnchor:"end"},
      {cx:rightCircle.x,cy:rightCircle.y,text:names[b],x:rightCircle.x,y:rightCircle.y+circleRadius+2*textPadding,textAnchor:"start"},
      {cx:topCircle.x,cy:topCircle.y,text:names[c],x:topCircle.x,y:topCircle.y-circleRadius - textPadding,textAnchor:"middle"},
      {cx:bottomCircle.x,cy:bottomCircle.y,text:names[d],x:bottomCircle.x,y:bottomCircle.y+circleRadius+2*textPadding,textAnchor:"middle"},
    ]
    const afterCircles = [
      {cx:leftCircle.x,cy:leftCircle.y,text:names[a],x:leftCircle.x,y:leftCircle.y + circleRadius + 2*textPadding,textAnchor:"end"},
      {cx:rightCircle.x,cy:rightCircle.y,text:names[b],x:rightCircle.x,y:rightCircle.y + circleRadius + 2*textPadding,textAnchor:"start"},
      {cx:topCircle.x,cy:topCircle.y,text:names[c],x:topCircle.x,y:topCircle.y - circleRadius - textPadding,textAnchor:"middle"},
      {cx:bottomCircle.x,cy:bottomCircle.y,text:names[d],x:bottomCircle.x,y:bottomCircle.y + circleRadius + 2*textPadding,textAnchor:"middle"},
    ]

    return {
      before: {
        circles: beforeCircles,
        easyText:{line1:"", line2:"", main:""},
        lines: beforeLines,
        texts: beforeText,
        title: beforeTitle,
      },
      after: {
        circles: afterCircles,
        easyText:{line1:"", line2:"", main:""},
        lines: afterLines,
        texts: afterText,
        title: afterTitle,
      },
    }
  },
}

export default stepDrawFunctions

//sorts the lines so that the highest value has the darkest color
function processLines(
  lines: LineType[],
  colors: string[],
  colorsOffset: number,
  strokeWidth?: number,
) {
  lines.sort(objFieldCmp("value")) //sort by value
  for(let i=0; i<lines.length; ++i) {
    lines[i].stroke = colors[i + colorsOffset]
    if(strokeWidth) lines[i].strokeWidth = strokeWidth
  }
}

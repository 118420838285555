import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'

import jwtState from 'appRecoil/jwt'

import { API, INPUT_RESTRICTIONS, ROUTES, SERVER_URL } from 'utils/constants'
import getFetchOptions from "utils/getFetchOptions"
import type { GroupType } from 'utils/group'
import handleResponse from 'utils/handleResponse'
import type { InputDataType } from "utils/inputData"

import AccessCodeForm from "./AccessCodeForm"

type Props = {
  setGroup: (group: GroupType) => void,
}

const AccessCodeFormContainer = (props: Props) => {
  const jwt = useRecoilValue(jwtState)

  const [accessCode, setAccessCode] = useState<string>("")
  const [accessCodeErr, setAccessCodeErr] = useState<boolean>(false)
  const [status, setStatus] = useState<string>("")

  const submit = (url: string) => {
    setAccessCodeErr(false)
    setStatus("")

    //form validation
    if(accessCode.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      setAccessCodeErr(true)
      setStatus("Access code is too long")
    }
    else if(accessCode.length === 0) {
      setAccessCodeErr(true)
      setStatus("You must provide an access code")
    }
    else {
      setStatus("loading")
      fetch(`${SERVER_URL}${API.VIEW}/${url}`, {
        ...getFetchOptions(jwt),
        method: 'PUT',
        body: JSON.stringify({ accessCode }),
      }).then(handleResponse).then(res => res.json()).then(data => {
        // console.log("SUCCESSFUL ACCESS CODE", data)
        props.setGroup(data)
      }).catch(err => {
        setStatus(err.message)
        console.error(err)
      })
    }
  }


  return (
    <AccessCodeForm
      inputData={[{
        error: accessCodeErr,
        label:"",
        placeholder: "Access Code",
        required: true,
        rest: { maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, type: "password" },
        set: setAccessCode,
        value: accessCode,
      }]}
      status={status}
      submit={submit}
    />
  )
}

export default AccessCodeFormContainer

export type AccessCodeFormProps = {
  inputData: InputDataType[],
  status: string,
  submit: (url:string) => void,
}

import React, { useCallback, useEffect, useRef } from 'react'

import { CircleType, LineType, TextType } from "./stepDrawFunctions"
import "./steps.scss"

import type { StepsProps } from "./StepsContainer"

const Steps = (props: StepsProps) => {
  const {
    changeStep,
    currentStep,
    drawOutputData,
    easyText,
    index,
    setWidth,
    sizingData: {
      circleRadius,
      fontSize,
      height,
      strokeWidth,
      textPadding,
    },
    steps,
    stepTitle,
    width,
  } = props


  const parentRef = useRef<HTMLDivElement>(null)

  const resize = useCallback(
    () => parentRef.current && setWidth(parentRef.current.clientWidth),
    []
  )

  React.useEffect(() => { //componentDidMount
    window.addEventListener( "resize", resize ) //resize if the window changes
    resize() //initial resize
    return () => { //componentWillUnmount
      window.removeEventListener( "resize", resize )
    }
  }, [])


  const getContent = () => {
    if(steps.length > 1) {
      return (
        <React.Fragment>
          <div id="stepsControl">
            <StepButtons
              changeStep={changeStep}
              index={index}
              stepsLength={steps.length}
            />

            <strong id="stepNumber">{stepTitle}</strong>
          </div>

          <div id="stepSVGs" className="two column doubling ui grid">
            <div className="column">
              <div ref={parentRef}>
                <Shape
                  title={drawOutputData.before.title}
                  lines={drawOutputData.before.lines}
                  texts={drawOutputData.before.texts}
                  circles={drawOutputData.before.circles}
                  easyText={easyText.before}

                  circleRadius={circleRadius}
                  fontSize={fontSize}
                  height={height}
                  preTitle="Before:"
                  strokeWidth={strokeWidth}
                  width={width}

                />
              </div>
            </div>
            <div id="afterColumn" className="column">
              <div>
                <Shape
                  title={drawOutputData.after.title}
                  lines={drawOutputData.after.lines}
                  texts={drawOutputData.after.texts}
                  circles={drawOutputData.after.circles}
                  easyText={easyText.after}

                  circleRadius={circleRadius}
                  fontSize={fontSize}
                  height={height}
                  preTitle="After:"
                  strokeWidth={strokeWidth}
                  width={width}

                />
              </div>
            </div>
          </div>


          <div>
            <StepButtons
              changeStep={changeStep}
              index={index}
              stepsLength={steps.length}
            />
          </div>
        </React.Fragment>
      )
    }
    else {
      return (
        <div>
          <div><b>Debtdelta did not have to do any major calculations! <span style={{color: "green"}}>✔</span></b></div>
          <br/>
        </div>
      )
    }
  }



  return (
    <div id="steps" className="ui container">
      <h3 className="ui dividing header">
        Calculations
      </h3>

      {getContent()}
    </div>
  );
}

export default Steps




const StepButtons = (props:{
  changeStep: Function,
  index: number,
  stepsLength: number,
}) => {
  return (
    <React.Fragment>
      <button className="ui icon button" disabled={props.index <= 0} onClick={() => props.changeStep(-1)}>
        <i className="left arrow icon"></i>
      </button>

      <button className="stepNextButton ui teal icon button" disabled={props.index >= props.stepsLength-2} onClick={() => props.changeStep(1)}>
        <i className="right arrow icon"></i>
      </button>
    </React.Fragment>
  )
}



const Shape = (props:{
  circleRadius: number
  circles: CircleType[],
  easyText: {
    line1: string,
    line2: string,
    main: string,
  },
  fontSize: number,
  height: number,
  lines: LineType[],
  preTitle: string,
  strokeWidth: number,
  texts: TextType[],
  title: string,
  width: number
}) => {
  return (
    <React.Fragment>
      <div className="stepTitle"><strong>{props.preTitle} </strong>{props.title}</div>
      <svg width={props.width} height={props.height}>
        {props.lines.map((l, i) =>
          <line key={i} x1={l.x1} x2={l.x2} y1={l.y1} y2={l.y2} strokeWidth={l.strokeWidth} stroke={l.stroke}></line>
        )}

        {props.texts.map((t, i) =>
          <text key={i} x={t.x} y={t.y} dy="5" textAnchor="middle" fill="white" transform={t.transform} fontSize={props.fontSize+"px"}>{t.text}</text>
        )}

        {props.circles.map((c, i) =>
          <React.Fragment key={i}>
            <circle cx={c.cx} cy={c.cy} r={props.circleRadius} fill="white" strokeWidth={props.strokeWidth} stroke="black"></circle>
            <text x={c.x} y={c.y} textAnchor="middle" fontSize={props.fontSize+"px"}>{c.text}</text>
          </React.Fragment>
        )}

        <text x={props.width/2} y={2*props.height/5} textAnchor="middle" fontSize={props.width/20}>{props.easyText.line1}</text>
        <text x={props.width/2} y={3*props.height/5} textAnchor="middle" fontSize={props.width/20}>{props.easyText.line2}</text>
        <text x={props.width/2} y={props.height/2} textAnchor="middle" fontSize={props.width/4} dy={props.width/8} fill="green">{props.easyText.main}</text>
      </svg>
    </React.Fragment>
  )
}

import React, { useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import jwt_decode from "jwt-decode"

import isAuthenticatedState, { AuthenticationEnum } from 'appRecoil/isAuthenticated'
import jwtState from 'appRecoil/jwt'

import NavBar from "./NavBar"

export const FEEDBACK_FORM_URL = "https://forms.gle/frpzDY1QR1LSywX37"

const NavBarContainer = () => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(isAuthenticatedState)
  const jwt = useRecoilValue(jwtState)

  const username = useMemo(() => {
    if(isAuthenticated) {
      try {
        return (jwt_decode(jwt) as {username: string}).username
      }
      catch(err) {
        console.error(err)
      }
    }

    return ""
  }, [isAuthenticated, jwt])

  function logout() {
    setIsAuthenticated(AuthenticationEnum.not)
  }

  return (
    <NavBar
      isAuthenticated={isAuthenticated}
      logout={logout}
      username={username}
    />
  )
}


export default NavBarContainer

export type NavBarProps = {
  isAuthenticated: AuthenticationEnum,
  logout: Function,
  username: string,
}

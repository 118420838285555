import React from 'react';
import { AuthenticationEnum } from 'appRecoil/isAuthenticated'

import ServerFailed from "Components/ServerFailed/ServerFailed"
import WaitForServerContainer from "Components/WaitForServer/WaitForServerContainer"

export default function serverCheck(
  isAuthenticated:AuthenticationEnum,
  callback: () => React.ReactNode
):React.ReactNode {
  switch (isAuthenticated) {
    case AuthenticationEnum.failed:
      return <ServerFailed/>
    case AuthenticationEnum.pending:
      return <WaitForServerContainer/>
    default:
      return callback()
  }
}

import React, { ReactNode } from 'react'
import "./modal.scss"

type Props = {
  canClose?: boolean,
  children: React.ReactNode,
  fullscreen?: boolean,
  hide?: Function,
  show: boolean,
  simple?: boolean,
  transition: number,
}

const Modal = (props: Props) => {
  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if(props.canClose !== false && props.hide) {
      props.hide()
    }
  }

  return (
    <div
      className={`modal${props.show?" show":""}${props.fullscreen?" fullscreen":""}${props.simple===true?" simple":""}`}
      onClick={onClick}
      style={{transition: `${props.transition}s`}}
    >
      <div className="modalContainer" onClick={e => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  )
}

Modal.Header = (props:{children: ReactNode}) => <div className="header">{props.children}</div>
Modal.Content = (props:{children: ReactNode}) => <div className="content">{props.children}</div>
Modal.Footer = (props:{children: ReactNode}) => <div className="footer">{props.children}</div>


Modal.defaultProps = {
  transition: 0.5,
}

export default Modal

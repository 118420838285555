import React from 'react'

import "./messages.scss"

type MessagesProps = {
  children?: string | React.ReactNode,
  error?: boolean,
  heading?: string,
  messages?: (string | React.ReactNode)[],
  slow?: boolean,
  success?: boolean,
}

export default function Messages (props: MessagesProps) {
  const {
    children,
    error,
    heading,
    messages,
    slow,
    success
  } = props

  const typeClass = success ? "success" : (
    error ? "error" : ""
  )
  const className = `messages ${typeClass} ${slow?"slow":""}`
  if(messages && messages.length > 0) {
    return (
      <div className={className}>
        <div><b>{heading}</b></div>
        <ul>
          {messages.map((m,i) =>
            <li key={i}>{m}</li>
          )}
        </ul>
      </div>
    )
  }
  else if(children) {
    return <div className={className}>{children}</div>
  }

  return null
}

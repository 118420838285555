import React, { useEffect, useReducer, useRef  } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Message } from 'semantic-ui-react'

import groupState from 'appRecoil/group'
import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import BottomBar from "Components/BottomBar/BottomBar"
import ScrollForm, { ScrollFormContext, ScrollFormQuestionContextType, withScrollFormQuestionContext } from "Components/ScrollForm/ScrollForm"
import ScrollFormBottomBar from "Components/ScrollForm/ScrollFormBottomBar"
import ScrollFormInput from "Components/ScrollForm/ScrollFormInput"
import ScrollFormOkButton from "Components/ScrollForm/ScrollFormOkButton"

import binaryInsert from 'utils/binaryInsert'
import binarySearch from 'utils/binarySearch'
import { DEFAULT_OWER } from 'utils/group'
import parseInputAmount, { AMOUNT_FACTOR } from 'utils/parseInputAmount'
import { INPUT_RESTRICTIONS } from 'utils/constants'
import type { OwerType } from 'utils/group'
import pushElementToArrayCopy from 'utils/pushElementToArrayCopy'

import "./addOwersForm.scss"

type OwerActionType =
| { type: 'description', description: string }
| { type: 'deselectAllOwers' }
| { type: 'inputAmount', inputAmount: string }
| { type: 'reset' }
| { type: 'selectAllOwers', names: string[] }
| { type: 'toggleOwer', isSelected: boolean, name: string }

const initOwer = () => JSON.parse(JSON.stringify(DEFAULT_OWER))

export function owerReducer(
  ower:OwerType,
  action: OwerActionType,
):OwerType {
  switch (action.type) {
    case 'description':
      return {...ower, description: action.description}
    case 'deselectAllOwers':
      return {...ower, names: []}
    case 'inputAmount':
      return {...ower, amount: parseInputAmount(action.inputAmount)}
    case 'reset':
      return initOwer()
    case 'selectAllOwers':
      return {...ower, names: action.names}
    case 'toggleOwer':
      const namesCopy = ower.names.slice()
      if(action.isSelected) {
        const index = binarySearch(ower.names, action.name)
        namesCopy.splice(index, 1)
      }
      else {
        binaryInsert(namesCopy, action.name)
      }
      return {...ower, names: namesCopy}
    default:
      throw new Error(`Unexpected action`)
  }
}


type Props = {
  hide: Function,
  isShown: boolean,
  triggerReset: boolean,
}

const AddOwersForm = (props: Props) => {
  const group = useRecoilValue(groupState)
  const [transaction, setTransaction] = useRecoilState(transactionState)

  const [ower, dispatchOwer] = useReducer(owerReducer, initOwer())

  const submit = (resetForm: Function) => {
    const submitOwer = {...ower, description: ower.description.trim()}
    dispatchTransaction(transaction, setTransaction, { type: 'addOwer', ower: submitOwer })
    resetForm()
    props.hide()
    dispatchOwer({type: "reset"})
  }

  const questions = [
    {
      canGoToNextQuestion: ower.description.trim().length > 0,
      element: (
        <ScrollFormInput
          errorMessage="Please enter a description"
          isShown={props.isShown}
          label="What was paid for?"
          maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => dispatchOwer({type: "description", description: e.target.value})}
          placeholder={"Ex: Tacos"}
          required
          type="text"
          value={ower.description}
        />
      ),
    },
    {
      canGoToNextQuestion: ower.names.length > 0,
      element: (
        <ScrollFormContext.Consumer>
          {(scrollFormContext) => (
            <div className="ui form">
              <div className="required field"><label>Who was paid for?</label></div>
              <br/>
              <div>
                <span className="clickableText" onClick={e => dispatchOwer({type: "selectAllOwers", names: group.members.map(m => m.name)})}>Select All</span>
                &nbsp;&nbsp;/&nbsp;&nbsp;
                <span className="clickableText" onClick={e => dispatchOwer({type: "deselectAllOwers"})}>Deselect All</span>
              </div>
              <hr/>
              <div>
                {group.members.map((member, memberIndex) => {
                  const isSelected = ower.names.includes(member.name)

                  return (
                    <button
                      key={memberIndex}
                      className={`ui button addOwerButton ${isSelected?"greenButton":"basic"}`}
                      onClick={e => dispatchOwer({type: 'toggleOwer', isSelected, name: member.name })}
                    >
                      {member.name}
                    </button>
                  )
                })}
              </div>
              <br/>
              <div style={{opacity: scrollFormContext.canGoToNextQuestion?1:0}}>
                <ScrollFormOkButton/>
              </div>
            </div>
          )}
        </ScrollFormContext.Consumer>
      )
    },
    {
      canGoToNextQuestion: ower.amount > 0,
      element: (
        <ScrollFormInput
          errorMessage="Please enter an amount"
          isShown={props.isShown}
          label="How much was this item?"
          min={0}
          okMessage={(
            <ScrollFormContext.Consumer>
              {(scrollFormContext) => (
                <React.Fragment>
                  <button className="ui button" onClick={e => submit(scrollFormContext.resetForm)}>Submit</button> <span>press Enter</span>
                </React.Fragment>
              )}
            </ScrollFormContext.Consumer>
          )}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => dispatchOwer({type: "inputAmount", inputAmount: e.target.value})}
          required
          type="number"
          value={ower.amount / AMOUNT_FACTOR || ""}
        />
      )
    },
  ]



  const goToQuestionCallback = (questionIndex: number, validQuestionIndex: boolean, resetForm: Function) => {
    if(questionIndex === questions.length) {
      submit(resetForm)
    }
  }

  return (
    <div className="fullscreen addOwersForm">
      <h3 className="fullscreenHeader">Add Transaction Item</h3>

      <ScrollForm
        goToQuestionCallback={goToQuestionCallback}
        questions={questions}
        triggerReset={props.triggerReset}
      >
        <ScrollFormBottomBar
          back={props.hide}
          numQuestions={questions.length}
        />
      </ScrollForm>
    </div>
  )
}

export default AddOwersForm

import React from "react"
import { Link } from "react-router-dom"

import { ROUTES } from 'utils/constants'

import "./redirectMyGroups.scss"

const RedirectMyGroups = () => {
  return (
    <div className="redirectMyGroups">
      <div>
        <div><b>You need to select which group you want to edit</b></div>
        <br/>
        <Link to={ROUTES.MY_GROUPS}><button className="ui button">Go to My Groups</button></Link>
      </div>
    </div>
  )
}

export default RedirectMyGroups

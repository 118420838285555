import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import type { RouteComponentProps } from 'react-router'

import isAuthenticatedState, { AuthenticationEnum } from 'appRecoil/isAuthenticated'

import { API, INPUT_RESTRICTIONS, ROUTES, SERVER_URL } from "utils/constants"
import handleResponse from 'utils/handleResponse'

import Login from './Login'

const LoginContainer = (props: RouteComponentProps) => {
  const [isAuthenticated, setIsAuthenticated] = useRecoilState(isAuthenticatedState)

  const [username, setUsername] = useState<string>("")
  const [usernameErr, setUsernameErr] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [passwordErr, setPasswordErr] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])


  function submit() {
    setUsernameErr(false)
    setPasswordErr(false)
    setErrors([])

    let errors:string[] = []

    //form validation
    if(username.trim().length === 0) { //this checks for empty input
      errors.push("Please enter a valid username")
      setUsernameErr(true)
    }
    if(username.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      errors.push(`Username must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`)
      setUsernameErr(true)
    }
    if(password.length === 0) {
      errors.push("Please enter your password")
      setPasswordErr(true)
    }
    if(password.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      errors.push(`Password must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`)
      setPasswordErr(true)
    }

    //if there is no error
    if(errors.length === 0) {
      fetch(`${SERVER_URL}${API.LOGIN}`, {
        method: 'PUT', // *GET, PUT, POST, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          username,
          password,
        })
      }).then(handleResponse).then(data => {
        setIsAuthenticated(AuthenticationEnum.authenticated)
      }).catch(err => {
        console.error(err)
        errors.push(err.message)
        setErrors(errors)
      })
    }
    else {
      setErrors(errors)
    }
  }



  return (
    <Login
      errors={errors}
      password={password}
      passwordErr={passwordErr}
      setPassword={setPassword}
      setUsername={setUsername}
      submit={submit}
      username={username}
      usernameErr={usernameErr}
    />
  )
}

export default LoginContainer

export type LoginProps = {
  errors: string[],
  password: string,
  passwordErr: boolean,
  setPassword: (password:string) => void,
  setUsername: (username:string) => void,
  submit: () => void,
  username: string,
  usernameErr: boolean,
}

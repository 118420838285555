import React, { useMemo, useState } from "react"
import { useRecoilState } from 'recoil'
import H from 'history'

import groupState from 'appRecoil/group'

import { API, INPUT_RESTRICTIONS, ROUTES, SERVER_URL } from 'utils/constants'
import handleResponse from 'utils/handleResponse'
import type { InputDataType } from "utils/inputData"
import { RAW_GROUP } from 'utils/group'
import type { GroupType } from 'utils/group'

import Info from "./Info"

const InfoContainer = () => {
  const [group, setGroup] = useRecoilState(groupState)
  const isEditingGroup = group._id.length > 0
  const title = isEditingGroup ? `Edit ${group.name}` : "Create a New Group"

  const [accessCode, setAccessCode] = useState<string>("")
  const [accessCodeErr, setAccessCodeErr] = useState<boolean>(false)
  const [description, setDescription] = useState<string>("")
  const [descriptionErr, setDescriptionErr] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])
  const [name, setName] = useState<string>("")
  const [nameErr, setNameErr] = useState<boolean>(false)

  //prefill in inputs in case the group was set too slowly
  useMemo(() => {
    if(group) { //if there is a group in state, prefill the inputs
      setAccessCode(group.accessCode || "")
      setDescription(group.description || "")
      setName(group.name || "")
    }
  }, [group])

  const submit = (history: H.History) => {
    let formErrors:string[] = []
    setAccessCodeErr(false)
    setDescriptionErr(false)
    setNameErr(false)

    const submitDescription = description.trim()
    const submitName = name.trim()

    //form validation
    if(submitName.trim().length === 0) { //this checks for empty input
      formErrors.push("Please enter a meaningful name")
      setNameErr(true)
    }
    if(submitName.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      formErrors.push(`Name must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`)
      setNameErr(true)
    }
    if(submitDescription.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      formErrors.push(`Description must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`)
      setDescriptionErr(true)
    }
    if(accessCode.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      formErrors.push(`Access Code must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`)
      setAccessCodeErr(true)
    }

    if(formErrors.length === 0) {
      const body = {
        accessCode,
        description: submitDescription,
        name: submitName,
      }

      if(isEditingGroup) { //edit an existing group
        fetch(`${SERVER_URL}${API.GROUP}`, {
          method: 'PUT',
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({
            ...body,
            _id: group._id,
          }),
        }).then(handleResponse).then(
          res => res.json()
        ).then(data => {
          // console.log("EDITED", data)
          setGroup({
            ...group,
            ...body,
            ...data,
          })
          history.push(`${ROUTES.MEMBERS}`)
        }).catch(err => {
          setErrors([err.message])
        })
      }
      else { //create a new group
        fetch(`${SERVER_URL}${API.GROUP}`, {
          method: 'POST',
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(body),
        }).then(handleResponse).then(
          res => res.json()
        ).then(data => {
          // console.log("CREATED", data)
          setGroup({
            ...RAW_GROUP,
            ...body,
            ...data,
          })
          history.push(`${ROUTES.MEMBERS}`)
        }).catch(err => {
          setErrors([err.message])
        })
      }
    }
    else {
      setErrors(formErrors) //TODO
    }
  }

  return (
    <Info
      errors={errors}
      group={group}
      inputData={[
        {
          error: nameErr,
          label:"Name",
          placeholder: "Ex: Vacation",
          required: true,
          rest: { maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, type: "text" },
          set: setName,
          value: name,
        },
        {
          error: descriptionErr,
          label:"Description",
          placeholder: "Optional",
          required: false,
          rest: { maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, type: "text" },
          set: setDescription,
          value: description,
        },
        {
          error: accessCodeErr,
          label:"Access Code to View This Group",
          placeholder: "Optional",
          required: false,
          rest: { maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, type: "text" },
          set: setAccessCode,
          value: accessCode,
        },
      ]}
      submit={submit}
      title={title}
    />
  )
}

export default InfoContainer


export type InfoProps = {
  errors: string[],
  group: GroupType,
  inputData: InputDataType[],
  submit: (history: H.History) => void,
  title: string,
}

import { atom } from 'recoil'

//this atom is primarily meant for mobile only
//which needs to set the jwt manually in a custom header
const init = () => ""
const jwtState = atom<string>({
  key: 'jwt',
  default: init(),
})

export default jwtState

import React from 'react'
import './bottomBar.scss'

type Props = {
  children: React.ReactNode,
}

const BottomBar = (props: Props) => (
  <div className="bottomBar">
    <div className="ui container">
      <div className="bottomBarContent">{props.children}</div>
    </div>
  </div>
)

export default BottomBar

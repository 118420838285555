import React, { useMemo, useState } from "react"
import { useRecoilState, useRecoilValue } from 'recoil'
import H from 'history'

import groupState, { dispatchGroup } from 'appRecoil/group'
import jwtState from 'appRecoil/jwt'
import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import { API, ROUTES, SERVER_URL } from 'utils/constants'
import deleteElementFromArrayCopy from 'utils/deleteElementFromArrayCopy'
import getFetchOptions from 'utils/getFetchOptions'
import type { GroupType } from 'utils/group'
import handleResponse from 'utils/handleResponse'

import Groups from "./Groups"

const GroupsContainer = () => {
  const [group, setGroup] = useRecoilState(groupState)
  const jwt = useRecoilValue(jwtState)
  const [transaction, setTransaction] = useRecoilState(transactionState)

  const [completedGroups, setCompletedGroups] = useState<GroupType[]>([])
  const [draftGroups, setDraftGroups] = useState<GroupType[]>([])
  const [deleteDraftGroupIndex, setDeleteDraftGroupIndex] = useState<number>(-1)
  const resetDeleteDraftGroup = () => setDeleteDraftGroupIndex(-1)

  useMemo(() => {
    fetch(`${SERVER_URL}${API.GROUPS}`, {
      ...getFetchOptions(jwt),
      method: 'GET',
    }).then(handleResponse).then(
      res => res.json()
    ).then((data:GroupType[]) => {
      const completedGroups = data.filter(g => g.url !== "")
      const draftGroups = data.filter(g => g.url === "")
      setCompletedGroups(completedGroups)
      setDraftGroups(draftGroups)
      // console.log("DONE", data)
    }).catch(err => {
      console.error(err)
    })
  }, [])

  const groupToDelete = draftGroups[deleteDraftGroupIndex]

  const deleteDraft = () => {
    if(groupToDelete) {
      fetch(`${SERVER_URL}${API.GROUP}`, {
        ...getFetchOptions(jwt),
        method: 'DELETE',
        body: JSON.stringify({
          _id: groupToDelete._id,
        })
      }).then(handleResponse).then(() => {
        setDraftGroups(deleteElementFromArrayCopy(draftGroups,deleteDraftGroupIndex))
        resetDeleteDraftGroup()
        // console.log("DELETED GROUP")
      }).catch(err => {
        console.error(err)
      })
    }
  }


  const onClickNewGroup = (history:H.History) => {
    dispatchGroup(group, setGroup, {type:"reset"})
    dispatchTransaction(transaction, setTransaction, {type:"reset"})
    setTimeout(() => history.push(ROUTES.INFO), 1)
  }

  return (
    <Groups
      completedGroups={completedGroups}
      deleteDraft={deleteDraft}
      draftGroups={draftGroups}
      groupToDelete={groupToDelete}
      onClickNewGroup={onClickNewGroup}
      resetDeleteDraftGroup={resetDeleteDraftGroup}
      setDeleteDraftGroupIndex={setDeleteDraftGroupIndex}
      setGroup={setGroup}
    />
  )
}

export default GroupsContainer


export type GroupsProps = {
  completedGroups: GroupType[],
  deleteDraft: () => void,
  draftGroups: GroupType[],
  groupToDelete: GroupType,
  onClickNewGroup: (history: H.History) => void,
  resetDeleteDraftGroup: () => void,
  setDeleteDraftGroupIndex: (index: number) => void,
  setGroup: (group: GroupType) => void,
}

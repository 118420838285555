import React from 'react'
import { useRecoilValue } from 'recoil'
import { Link, useHistory } from 'react-router-dom'

import isAuthenticatedState from 'appRecoil/isAuthenticated'

import { ROUTES } from "utils/constants"

import "./notFound.scss"

const NotFound = () => {
  const history = useHistory()

  const isAuthenticated = useRecoilValue(isAuthenticatedState)

  return (
    <div id="notFoundContainer" className="fullPage">
      <div className="ui container">
        <h2>Sorry! We couldn't find what you were looking for :(</h2>

        <div>
          <button className="ui button" onClick={e => history.goBack()}>Back</button>
          {
            isAuthenticated
            ? <Link to={ROUTES.MY_GROUPS}><button className="ui button">Go to My Groups</button></Link>
            : <Link to={ROUTES.LANDING}><button className="ui button">Go to Home</button></Link>
          }
        </div>
      </div>
    </div>
  )
}

export default NotFound

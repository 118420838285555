import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil'
import isAuthenticatedState, { AuthenticationEnum } from 'appRecoil/isAuthenticated'

import GroupsContainer from "Containers/Groups/GroupsContainer"
import InfoContainer from "Containers/Info/InfoContainer"
import Landing from "Containers/Landing/Landing"
import LoginContainer from "Containers/Authentication/Login/LoginContainer"
import Members from "Containers/Members/Members"
import NavBarContainer from "Containers/NavBar/NavBarContainer"
import NotFound from "Containers/NotFound/NotFound"
import Register from "Containers/Authentication/Register/Register"
import serverCheck from 'Containers/serverCheck'
import Transactions from "Containers/Transactions/Transactions"
import ViewContainer from "Containers/View/ViewContainer"

import { API, ROUTES, SERVER_URL } from 'utils/constants'
import getCookie from "utils/getCookie"
import handleResponse from 'utils/handleResponse'
import useAutoLogin from 'utils/useAutoLogin'
import useUpdateJwt from 'utils/useUpdateJwt'

import packageJson from "../package.json"

import "App.scss"

console.log(`Version ${packageJson.version}`)

const App = () => {
  useAutoLogin()
  useUpdateJwt(async () => getCookie("jwt"))

  return (
    <Router>
      <NavBarContainer/>

      <Switch>
        <Route exact path={ROUTES.LANDING} component={Landing}/>

        <ServerDependentRoute exact path={`${ROUTES.VIEW}/:url`} component={() => <ViewContainer useParams={useParams}/>}/>

        <LoggedOutRoute exact path={ROUTES.REGISTER} component={Register}/>
        <LoggedOutRoute exact path={ROUTES.LOGIN} component={LoginContainer}/>

        <LoggedInRoute exact path={ROUTES.INFO} component={InfoContainer}/>
        <LoggedInRoute exact path={ROUTES.MY_GROUPS} component={GroupsContainer}/>
        <LoggedInRoute exact path={ROUTES.MEMBERS} component={Members}/>
        <LoggedInRoute exact path={ROUTES.TRANSACTIONS} component={Transactions}/>

        <Route component={NotFound}/>
      </Switch>
    </Router>
  );
}



function ServerDependentRoute(props: any) {
  const {
    component: Component,
    ...rest
  } = props

  const isAuthenticated = useRecoilValue(isAuthenticatedState)

  return (
    <Route
      {...rest}
      render={(props) =>
        serverCheck(
          isAuthenticated,
          () => <Component/>
        )
      }
    />
  )
}


//routes that only a logged in user can access (email verified or unverified)
function LoggedInRoute(props: any) {
  const {
    component: Component,
    ...rest
  } = props

  const isAuthenticated = useRecoilValue(isAuthenticatedState)

  return (
    <Route
      {...rest}
      render={(props) =>
        serverCheck(isAuthenticated, () => {
          switch (isAuthenticated) {
            case AuthenticationEnum.authenticated:
              return <Component/>
            case AuthenticationEnum.not:
              return <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }}/>
            default:
              return ""
          }
        })
      }
    />
  )
}


//pages that a user can only access if they are logged OUT (ex a logged in user should not be able to re-login or register)
function LoggedOutRoute(props: any) {
  const {
    component: Component,
    ...rest
  } = props

  const isAuthenticated = useRecoilValue(isAuthenticatedState)

  return (
    <Route
      {...rest}
      render={props =>
        serverCheck(isAuthenticated, () => {
          switch (isAuthenticated) {
            case AuthenticationEnum.not:
              return <Component/>
            case AuthenticationEnum.authenticated:
              return <Redirect to={{ pathname: ROUTES.MY_GROUPS, state: { from: props.location } }} />
            default:
              return ""
          }
        })
      }
    />
  )
}

export default App

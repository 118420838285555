import React from 'react'
import { Link } from 'react-router-dom'

import { ROUTES } from "utils/constants"
import add from "./add.jpg"
import create from "./create.jpg"
import easy from "./easy.svg"
import share from "./share.jpg"
import shield from "./shield.svg"
import web from "./web.svg"
import "./landing.scss"

const Landing = () => {
  return (
    <div id="landing">
      <div id="paybackEasy" className="staticBackground">
        <div id="paybackEasyContent">
          <br/>
          <h1 className="landingTitle">Payback made easy</h1>
          <p className="landingSubTitle">DebtDelta simplifies complex webs of debt, making it easy to pay others back.</p>
          <Link to={ROUTES.LOGIN}><button className="ui teal button">Get Started</button></Link>
        </div>
      </div>

      <div id="logoContainer" className="two column stackable ui grid">
        <div className="column">
          <div className="splitText maxHeight justifyRight"  style={{color: "var(--darkRed)"}}>
            <div>
              <div className="debtTriangleSplitText debtTriangleBolderText">Iris</div>
              <div className="debtTriangleSplitText">&nbsp;owes</div>
              <div className="debtTriangleSplitText debtTriangleBolderText">&nbsp;Shyam</div>
              <div className="debtTriangleSplitText">&nbsp;who also owes</div>
              <div className="debtTriangleSplitText debtTriangleBolderText">&nbsp;Lawrence</div>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="splitText maxHeight justifyLeft" style={{color: "var(--darkGreen)"}}>
            <div>
              <div className="debtTriangleSplitText">Why not have&nbsp;</div>
              <div className="debtTriangleSplitText debtTriangleBolderText">Iris&nbsp;</div>
              <div className="debtTriangleSplitText">pay back&nbsp;</div>
              <div className="debtTriangleSplitText debtTriangleBolderText">Lawrence&nbsp;</div>
              <div className="debtTriangleSplitText">directly?</div>
            </div>
          </div>
        </div>

        <div id="landingLogo">
          <img src="img/debtTriangle.svg" alt="logo"/>
        </div>
      </div>

{/*
      <div id="mapMathHassle" className="staticBackground">
        <div>
          <h1 className="landingTitle">No math, no hassle</h1>

          <Link to={ROUTES.LOGIN}><button className="ui teal button">Get Started</button></Link>
        </div>
      </div> */}

      <section style={{paddingTop: "5vh", paddingBottom: "5vh"}}>
        <div className="ui container">
          <div className="noSpaceGrid two column stackable ui grid">
            <div className="column">
              <div className="beforeAfterCard">
                <img src={web}/>
                <h3 style={{color: "var(--darkRed)"}}>
                  After a group vacation or hangout, paying friends back is a hassle.
                </h3>
              </div>
            </div>
            <div className="column">
              <div className="beforeAfterCard">
                <img src={easy}/>
                <h3 style={{color: "var(--darkGreen)"}}>
                  DebtDelta calculates the simplest way to pay each other back.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{backgroundColor: "var(--darkGreen)", color: "white"}}>
        <div className="ui container" style={{textAlign:"center", paddingTop: "10vh", paddingBottom: "10vh"}}>
          <h3>
            You choose how to pay each other back. Debtdelta just figures out the easiest way to do it.
          </h3>
        </div>
      </section>


      {/* <div id="focusFun" className="staticBackground">
        <div>
          <h1 className="landingTitle">Focus on fun</h1>
          <Link to={ROUTES.LOGIN}><button className="ui teal button">Get Started</button></Link>
        </div>
      </div> */}

      <section>
        <div className="ui container">
          <div className="noSpaceGrid ui stackable grid">
            <div className="eight wide column">
              <div className="stackableText">
                <h1>Create</h1>
                <p>
                  Create a group and add your friends! Everything can be done on one account.
                </p>
              </div>
            </div>

            <div className="eight wide column stackableImageCol">
              <img id="createDeviceImg" className="multipleDeviceImg" src={create}/>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="ui container">
          <div className="noSpaceGrid ui computer reversed tablet reversed stackable grid">
            <div className="eight wide column">
              <div className="stackableText">
                <h1>Add</h1>
                <p>
                  Add all of your transactions - restaurant bills, Uber fares, Airbnb costs, etc. Keep track of who paid and who owes money.
                </p>
              </div>
            </div>

            <div className="eight wide column stackableImageCol">
              <img id="addDeviceImg" className="multipleDeviceImg" src={add}/>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="ui container">
          <div className="noSpaceGrid ui stackable grid">
            <div className="eight wide column">
              <div className="stackableText">
                <h1>Share</h1>
                <p>
                  Share the link with your friends! You decide how to pay each other (Venmo, cash, etc); Debtdelta just figures out the simplest way to do it.
                </p>
                <Link to={'/view/SquadHangout'}><button className="ui button">See an example!</button></Link>
              </div>
            </div>

            <div className="eight wide column stackableImageCol">
              <img id="shareDeviceImg" className="multipleDeviceImg" src={share}/>
            </div>
          </div>
        </div>
      </section>

      <section id="personalInfoSpam">
        <div className="ui container">
          <div className="ui stackable grid">
            <div className="five wide column">
              <img src={shield}/>
            </div>

            <div className="eleven wide column">
              <div className="text">
                <h1>No personal information. No spam.</h1>
                <p>
                  We promise never to sell your data or send you spam. Besides, we don't require any personal information anyways, not even an email.
                </p>
                <Link to={ROUTES.LOGIN}><button className="ui button">See for yourself!</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Landing

/**
 * binary search for the target in the array
 * @param  arr        array to search in
 * @param  target     the object to search for in the array
 * @param  comparator (optional) a method for comparing the target object type
 * @return            index of a matching item in the array if one exists, otherwise the bitwise complement of the index where the item belongs
 */
export default function binarySearch(
  arr: any[],
  target:any,
  comparator:Function = function (a:any, b:any) {
    return (a < b ? -1 : (a > b ? 1 : 0)) /* default comparison method if one was not provided */
  },
) {
  let l = 0
  let h = arr.length - 1

  while (l <= h) {
    let m = (l + h) >>> 1 /* equivalent to Math.floor((l + h) / 2) but faster */
    let comparison = comparator(arr[m], target)

    if (comparison < 0) {
      l = m + 1
    }
    else if (comparison > 0) {
      h = m - 1
    }
    else {
      return m
    }
  }

  return ~l
};

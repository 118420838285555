import React, { useCallback, useMemo, useState } from "react"
import { useRecoilState, useRecoilValue } from 'recoil'
import { Link, useHistory } from "react-router-dom"

import BottomBar from "Components/BottomBar/BottomBar"
import LoadingSpinner from "Components/LoadingSpinner/LoadingSpinner"
import Modal from "Components/Modal/Modal"
import RedirectMyGroups from "Containers/Groups/RedirectMyGroups"
import TransactionModal from "./TransactionModal/TransactionModal"

import groupState from 'appRecoil/group'
import jwtState from 'appRecoil/jwt'
import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import { AMOUNT_FACTOR } from 'utils/parseInputAmount'
import { API, ROUTES, SERVER_URL } from 'utils/constants'
import getFetchOptions from 'utils/getFetchOptions'
import handleResponse from 'utils/handleResponse'
import type { TransactionType } from 'utils/group'
import setDocumentTitle from 'utils/setDocumentTitle'

import "./transactions.scss"

const Transactions = () => {
  const history = useHistory()

  const [group, setGroup] = useRecoilState(groupState)
  setDocumentTitle(`Edit Transactions ${group.name}`)
  const jwt = useRecoilValue(jwtState)
  const [transaction, setTransaction] = useRecoilState(transactionState)

  const [calculateStatus, setCalculateStatus] = useState<string>("")
  const closeCalculateModal = () => setCalculateStatus("")
  const [editIndex, setEditIndex] = useState<number>(-1)

  const [editTransactionIndex, setEditMemberIndex] = useState<number>(-1)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [triggerReset, setTriggerReset] = useState<boolean>(false)
  const closeModal = () => setShowModal(false)

  const canSubmit = group.transactions.length !== 0

  const deleteTransaction = useCallback((transactionIndex) => {
    fetch(`${SERVER_URL}${API.TRANSACTION}`, {
      ...getFetchOptions(jwt),
      method: 'DELETE',
      body: JSON.stringify({
        _id: group._id,
        transactionIndex
      }),
    }).then(handleResponse).then(
      res => res.json()
    ).then(data => {
      setGroup({
        ...group,
        ...data,
      })
      // console.log("DELETED", data)

    }).catch(err => {
      setCalculateStatus("error")
      console.error(err) //TODO
    })
  }, [group])

  const submit = () => {
    if(canSubmit) {
      setCalculateStatus("loading")
      fetch(`${SERVER_URL}${API.CALCULATE}`, {
        ...getFetchOptions(jwt),
        method: 'PUT',
        body: JSON.stringify({
          _id: group._id
        }),
      }).then(handleResponse).then(
        res => res.json()
      ).then(data => {
        closeCalculateModal()
        // console.log("CALCULATE", data)
        history.push(`${ROUTES.VIEW}/${encodeURIComponent(data.url)}`)
      }).catch(err => {
        console.error(err) //TODO
      })
    }
  }

  const calculateStatusModalContent = useMemo(() => {
    switch (calculateStatus) {
      case "loading":
        return (
          <Modal.Header>
            <div>Calculating Debts...</div>
            <LoadingSpinner/>
          </Modal.Header>
        )
      default:
        return null
    }
  }, [calculateStatus])


  const getContent = () => {
    if(group._id === "") return <RedirectMyGroups/>

    return (
      <React.Fragment>
        <Modal canClose={false} hide={closeCalculateModal} show={calculateStatus.length > 0} simple>
          {calculateStatusModalContent}
        </Modal>

        <TransactionModal
          editIndex={editIndex}
          hide={closeModal}
          show={showModal}
          triggerReset={triggerReset}
        />

        <div className="ui container">
          <div>
            <h1 className="ui dividing header" style={{textAlign: "center"}}>Add Transactions</h1>

            <div style={{textAlign: "center"}}>
              <button className="ui right labeled icon button yellowButton" type="button" onClick={() => {
                setTriggerReset(!triggerReset)
                if(editIndex !== -1) { //if we were editing a transaction before, reset the state for this new transaction
                  dispatchTransaction(transaction, setTransaction, {type: "reset"})
                  setEditIndex(-1)
                }
                setShowModal(true)
              }}>
                Add New Transaction
                <i className="plus icon"></i>
              </button>

              {/* <button className="ui circular small icon button" type="button">
                <i className="question icon"></i>
              </button> */}
            </div>
          </div>

          <br/>

          {
            group && group.transactions.length > 0
            ? (
              <div>
                {group.transactions.map((t,i) =>
                  <React.Fragment key={i}>
                    <br/>
                    <OneTransaction
                      canEdit
                      edit={() => {
                        setTriggerReset(!triggerReset)
                        setTransaction(t)
                        setEditIndex(i)
                        setShowModal(true)
                      }}
                      delete={() => deleteTransaction(i)}
                      transaction={t}
                    />
                  </React.Fragment>
                )}
              </div>
            ) : <div style={{textAlign: "center"}}><b>You have not added any transactions</b></div>
          }
        </div>

        <BottomBar>
          <button className="ui right labeled icon button greenButton" disabled={!canSubmit} onClick={e => submit()}>
            Calculate!
            <i className="calculator icon"></i>
          </button>

          <button className="ui icon button" onClick={e => history.goBack()}>
            <i className="left chevron icon"></i>
          </button>
        </BottomBar>
      </React.Fragment>
    )
  }


  return (
    <div id="transactionContainer" className="newGroupBackground">
      {getContent()}
    </div>
  )
}

export default Transactions



type EditableTransactionProps = {
  canEdit: true,
  edit: Function,
  delete: Function,
}

type OneTransactionProps = (
  { canEdit: false } | EditableTransactionProps
) & {
  transaction: TransactionType,
}

export const OneTransaction = (props: OneTransactionProps) => {
  const {
    addOns,
    description,
    name,
    owers,
    payers,
    totalPaid,
  } =  props.transaction


  const [expanded, setExpand] = useState<boolean>(process.env.NODE_ENV === "development")
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const hideDeleteModal = () => setShowDeleteModal(false)

  return (
    <div className="transaction">
      <div className="ui equal width grid">
        <div className="row">
          <div className="six wide column"><strong>{name}</strong></div>
          <div className="six wide column moneyGreenFont">${totalPaid / AMOUNT_FACTOR}</div>
          <div className="four wide column">
            <i className={`angle ${expanded ? "up" : "down"} icon`} onClick={e => setExpand(!expanded)} style={{float: "right"}}></i>
          </div>
        </div>
      </div>

      <div className={`ui grid expandTransaction transition${expanded ? "" : " hidden"}`}>
        {description ? (
          <div className="row">
            <div className="sixteen wide column">
              <div><b>Description: </b> {description}</div>
            </div>
          </div>
        ) : null}

        <div className="transactionHR"></div>

        <div className="row">
          <div className="six wide column"><strong>Owers</strong></div>
          <div className="six wide column"><strong>Description</strong></div>
          <div className="four wide column"><strong>Cost</strong></div>
        </div>

        {owers.map((o,i) =>
          <div key={i} className="row">
            <div className="six wide column">{o.names.join(" / ")}</div>
            <div className="six wide column">{o.description}</div>
            <div className="four wide column moneyRedFont">${o.amount / AMOUNT_FACTOR}</div>
          </div>
        )}

        <div className="row">
          {addOns && addOns.map((a,i) =>
            <div key={i} className="eight wide column">
              <strong>{a.name}: </strong> &nbsp;
              <span className={a.amount > 0 ? "moneyRedFont" : (a.amount < 0 ? "moneyGreenFont" : "moneyGrayFont")}>${a.amount/AMOUNT_FACTOR}</span>
            </div>
          )}
        </div>


        <div className="transactionHR"></div>


        <div className="row">
          <div className="six wide column"><strong>Payers</strong></div>
          <div className="six wide column"><strong>Description</strong></div>
          <div className="four wide column"><strong>Paid</strong></div>
        </div>

        {payers.map((p,i) =>
          <div key={i} className="row">
            <div className="six wide column">{p.name}</div>
            <div className="six wide column">{p.description}</div>
            <div className="four wide column moneyGreenFont">${p.amount / AMOUNT_FACTOR}</div>
          </div>
        )}

        {
          props.canEdit ? (
            <React.Fragment>
              <div className="transactionHR"></div>

              <div className="row">
                <div className="eight wide column">
                  <button type="button" className="ui compact yellow icon button" onClick={e => props.edit()}>
                    <i className="edit icon"></i>
                  </button>
                </div>

                <div className="eight wide column">
                  <button type="button" className="ui compact red icon button" style={{float: "right"}} onClick={e => setShowDeleteModal(true)}>
                    <i className="remove icon"></i>
                  </button>
                </div>

                <Modal hide={hideDeleteModal} show={showDeleteModal}>
                  <Modal.Header>Delete Transaction</Modal.Header>
                  <Modal.Content>
                    <p>Are you sure you want to delete transaction <b>{name}</b>?</p>
                  </Modal.Content>
                  <Modal.Footer>
                    <button className="ui left labeled icon button" style={{float: "left"}} onClick={e => hideDeleteModal()}>
                      <i className="remove icon"></i>
                      No
                    </button>

                    <button className="ui red right labeled icon button" onClick={e => props.delete()}>
                      Yes
                      <i className="trash icon"></i>
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </React.Fragment>
          ) : null
        }
      </div>
    </div>
  )
}

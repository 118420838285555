import React, { useMemo, useState } from "react"
import { useHistory } from "react-router-dom"

import BottomBar from "Components/BottomBar/BottomBar"
import Input from "Components/Input/Input"
import Messages from "Components/Messages/Messages"

import { INPUT_RESTRICTIONS } from 'utils/constants'
import setDocumentTitle from 'utils/setDocumentTitle'

import { InfoProps } from "./InfoContainer"

const Info = (props: InfoProps) => {
  const {
    errors,
    group,
    inputData,
    submit,
    title,
  } = props

  const history = useHistory()

  setDocumentTitle(title)

  return (
    <div className="newGroupBackground">
      <form onSubmit={e => {
        e.preventDefault()
        submit(history)
      }}>
        <div className="ui container">
          <div>
            <h1 className="ui dividing header" style={{textAlign: "center"}}>{title}</h1>

            <div className="ui form">
              {inputData.map((d,i) =>
                <Input key={i} data={d}/>
              )}
            </div>

            <Messages error messages={errors}/>
          </div>
        </div>

        <BottomBar>
          <button className="ui right labeled icon button pinkButton" type="submit">
            Members
            <i className="right chevron icon"></i>
          </button>
        </BottomBar>
      </form>
    </div>
  )
}

export default Info

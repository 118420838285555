import { useCallback, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import isAuthenticatedState, { AuthenticationEnum } from 'appRecoil/isAuthenticated'
import jwtState from 'appRecoil/jwt'

export default function useUpdateJwt(
  getJwt: () => Promise<string>,
) {
  const isAuthenticated = useRecoilValue(isAuthenticatedState)
  const setJwt = useSetRecoilState(jwtState)

  const run = useCallback(async () => {
    try {
      setJwt(await getJwt()) //set the new jwt
    }
    catch(err) {
      console.error("Error setting JWT to state", err)
    }
  }, [getJwt])

  useEffect(
    () => { run() },
    [isAuthenticated, run] //run the effect whenever the authentication state changes
  )
}

import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown } from 'semantic-ui-react'

import Modal from "Components/Modal/Modal"
import { OneTransaction } from "Containers/Transactions/Transactions"

import { ROUTES } from 'utils/constants'
import setDocumentTitle from 'utils/setDocumentTitle'

import StepsContainer from "./Steps/StepsContainer"
import './debtDelta.scss'

import type { DebtDeltaProps } from './DebtDeltaContainer'


const DebtDelta = (props: DebtDeltaProps) => {
  const {
    amountText,
    deleteGroup,
    editGroup,
    filteredDebts,
    filteredMember,
    filteredMemberIndex,
    filteredMemberText,
    filterOptions,
    group,
    isOwner,
    setFilteredMemberIndex,
    simplifiedText,
  } = props

  setDocumentTitle(group.name)

  const history = useHistory()

  const [copyButtonText, setCopyButtonText] = useState<string>("Copy")
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const hideDeleteModal = () => setShowDeleteModal(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const hideEditModal = () => setShowEditModal(false)

  const copyUrl = () => {
    const copyUrl = document.querySelector("#copyUrl") as HTMLInputElement
    copyUrl.select()
    document.execCommand("copy")
    setCopyButtonText("Copied!")
  }

  //shows controls to edit the group only if this user is the owner of this group
  const showEditControls = () => {
    if(isOwner) {
      return (
        <React.Fragment>
          <button className="yellowButton ui icon button" style={{"float":"right"}} onClick={() => setShowEditModal(true)}>
            <i className="edit icon"></i>
          </button>

          <Modal hide={hideEditModal} show={showEditModal}>
            <Modal.Header>Edit Group</Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to edit this group? It will become a draft and be unviewable</p>
            </Modal.Content>
            <Modal.Footer>
              <button className="ui left labeled icon button" style={{float: "left"}} onClick={e => hideEditModal()}>
                <i className="remove icon"></i>
                No
              </button>

              <button className="ui yellow right labeled icon button" onClick={e => editGroup(history)}>
                Yes
                <i className="checkmark icon"></i>
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  }

  //shows controls to delete the group only if this user is the owner of this group
  const showDeleteControls = () => {
    if(isOwner) {
      return (
        <div className="section">
          <div className="ui container">
            <h3 className="ui dividing header">Delete</h3>

            <button className="red ui right labeled icon button" onClick={e => setShowDeleteModal(true)}>
              Delete Group
              <i className="trash icon"></i>
            </button>

            <Modal hide={hideDeleteModal} show={showDeleteModal}>
              <Modal.Header>Delete Group</Modal.Header>
              <Modal.Content>
                <p>Are you sure you want to permanantly delete this group?</p>
              </Modal.Content>
              <Modal.Footer>
                <button className="ui left labeled icon button" style={{float: "left"}} onClick={e => hideDeleteModal()}>
                  <i className="remove icon"></i>
                  No
                </button>

                <button className="ui red right labeled icon button" onClick={e => deleteGroup(history)}>
                  Yes
                  <i className="trash icon"></i>
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      );
    }
  }

  return (
    <div id="debtDelta">
      <div className="section">
        <div className="ui container">
          <div>
            {showEditControls()}

            <h1 className="ui dividing header">{props.group.name}</h1>
          </div>


          <div style={{"marginBottom":"1em"}}>
            {props.group.description ? (<React.Fragment><strong>Description: </strong> {props.group.description}</React.Fragment>) : ""}
          </div>

          <div>
            <div className="ui fluid action input">
              <input id="copyUrl" type="text" value={window.location.href} readOnly />
              <button className="ui right labeled teal icon button" onClick={e => copyUrl()}><i className="copy icon"></i><span>{copyButtonText}</span></button>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="ui container">
          <h3 className="ui dividing header">Simplified Debts</h3>
          <div className="ui flush stackable grid">
            <div className="eight wide column">
              <h3 style={{"color":"green"}}>{simplifiedText}</h3>
              <h3 style={{"color":"green"}}>{amountText}</h3>
            </div>

            <div className="eight wide column" style={{"fontSize":"16px"}}>
              <div className="ui sub header">Filter by Member</div>
              <Dropdown
                fluid
                onChange={(e, d) => {
                  setFilteredMemberIndex(d.value as number)
                }}
                options={filterOptions}
                placeholder='Filter by Member'
                selection
                value={filteredMemberIndex}
              />
              {filteredMember && (
                <h4 className={filteredMember.totalOwed > 0 ? "moneyRedFont" : "moneyGreenFont"}>{filteredMemberText}</h4>
              )}
            </div>
          </div>

          <div id="transactions" className="ui grid">
            <div className="four column row">
              <div className="column"><strong>Ower</strong></div>
              <div className="column"></div>
              <div className="column"><strong>Payer</strong></div>
              <div className="column"><strong>Amount Owed</strong></div>
            </div>
            {filteredDebts.map((d, i) =>
              <div key={i} className="four column row finalTransaction">
                <div className="column">{d.ower}</div>
                <div className="column">owes</div>
                <div className="column">{d.payer}</div>
                <div className="column">${d.amount}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      <StepsContainer group={props.group}/>

      <div className="section">
        <div className="ui container">
          <div className="ui flush stackable grid">
            <div className="four wide column">
              <h3 className="ui dividing header">Group Members</h3>
              <div>
                {props.group.members.map((m, i) =>
                  <div key={i}>{m.name}</div>
                )}
              </div>
            </div>

            <div className="twelve wide column">
              <h3 className="ui dividing header">Transactions</h3>
              <br />
              {props.group.transactions.map((t, i) =>
                <div key={i}>
                  <div className="transactionRow">
                    <OneTransaction canEdit={false} transaction={t}/>
                  </div>

                  <br/>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {showDeleteControls()}
    </div>
  )
}

export default DebtDelta

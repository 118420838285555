import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Input, Tab } from 'semantic-ui-react'

import Messages from "Components/Messages/Messages"

import { API, INPUT_RESTRICTIONS, ROUTES, SERVER_URL } from "utils/constants"
import handleResponse from 'utils/handleResponse'
import setDocumentTitle from 'utils/setDocumentTitle'

import type { LoginProps } from "./LoginContainer"

const Login = (props: LoginProps) => {
  setDocumentTitle("Login")

  const history = useHistory()

  const {
    errors,
    password,
    passwordErr,
    setPassword,
    setUsername,
    submit,
    username,
    usernameErr,
  } = props


  return (
    <Form onSubmit={e => {
      e.preventDefault()
      submit()
    }}>
      <br/>
      <div className="ui container">
        <h2>Login</h2>

        <Form.Field className={`field ${usernameErr ? "error" : ""}`}>
          <label>Username</label>
          <Input
            type="text" placeholder="Username" maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
            value={username} onChange={e => setUsername(e.target.value)}
          />
        </Form.Field>
        <Form.Field className={`field ${passwordErr ? "error" : ""}`}>
          <label>Password</label>
          <Input
            type="password" placeholder="Password" maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
            value={password} onChange={e => setPassword(e.target.value)}
          />
        </Form.Field>

        <br/>

        <p>(Starting in 2021, you will need to make a new account)</p>

        <div>
          <button className="ui button teal" type="submit">
            Log In
          </button>

          <button className="ui button" type="button" onClick={e => history.push(ROUTES.REGISTER)}>
            Register
          </button>
        </div>

        <Messages error messages={errors}/>
      </div>
    </Form>
  )
}

export default Login

type ReturnType = { //manually specify the type otherwise TypeScript complains
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'include', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json',
    'debtdelta-mobile-jwt': string, //typescript is really unhappy if this is possibly undefined
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
}

export default function getFetchOptions(
  jwt?: string,
):ReturnType {
  return {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'debtdelta-mobile-jwt': jwt || '',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }
}

import { atom } from 'recoil'

export enum AuthenticationEnum {
  failed = -2, //we have tried authenticating but we failed to reach the server
  pending, //we have not tested for authentication yet (ie on page load)
  not, //not authenticated, I want to make this visually distinctive from "authenticated"
  authenticated,
}

const isAuthenticatedState = atom<AuthenticationEnum>({
  key: 'authenticated',
  default: AuthenticationEnum.pending,
})

export default isAuthenticatedState

import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import isAuthenticatedState, { AuthenticationEnum } from 'appRecoil/isAuthenticated'
import jwtState from 'appRecoil/jwt'
import { API, SERVER_URL } from 'utils/constants'
import getFetchOptions from 'utils/getFetchOptions'

export default function useAutoLogin() {
  const setIsAuthenticated = useSetRecoilState(isAuthenticatedState)
  const jwt = useRecoilValue(jwtState)

  useEffect(() => {
    fetch(`${SERVER_URL}${API.IS_AUTHENTICATED}`, {
      ...getFetchOptions(jwt),
      method: 'GET', // *GET, PUT, POST, DELETE, etc.
    }).then(res => {
      if(res.ok) {
        setIsAuthenticated(AuthenticationEnum.authenticated)
      }
      else {
        setIsAuthenticated(AuthenticationEnum.not)
      }
    }).catch(err => {
      console.error(err)
      setIsAuthenticated(AuthenticationEnum.failed)
    })
  }, [])
}

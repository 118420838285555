import binarySearch from "./binarySearch"

/**
 * binary insert the target into the array
 * @param  arr        [description]
 * @param  target     the object to insert into the array
 * @param  duplicate  (optional) whether to insert the object into the array even if a matching object already exists in the array (false by default)
 * @param  comparator (optional) a method for comparing the target object type
 * @return            the index where the object was inserted into the array, or the index of a matching object in the array if a match was found and the duplicate parameter was false
 */
export default function binaryInsert(
  arr: any[],
  target: any, 
  duplicate: boolean = false,
  comparator: Function = function (a:any, b:any) {
    return (a < b ? -1 : (a > b ? 1 : 0)) /* default comparison method if one was not provided */
  },
) {
  let i = binarySearch(arr, target, comparator) //see if the target is already in the array
  if (i >= 0) { /* if the binarySearch return value was zero or positive, a matching object was found */
    if (!duplicate) { //if we don't allow duplicates
      return i //return the index
    }
  }
  else { /* if the return value was negative, the bitwise complement of the return value is the correct index for this object */
    i = ~i
  }

  arr.splice(i, 0, target) //splice in the target
  return i
};

import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState  } from 'react'
import BottomBar from "Components/BottomBar/BottomBar"

import type { ScrollFormQuestionContextType } from "Components/ScrollForm/ScrollForm"
import { withScrollFormQuestionContext } from "Components/ScrollForm/ScrollForm"

type Props = ScrollFormQuestionContextType & {
  back: Function,
  children: React.ReactNode,
  numQuestions: number,
}

const ScrollFormBottomBar = (props: Props) => {
  return (
    <BottomBar>
      <span>
        <button className="ui icon button" onClick={e => props.goToPrevQuestion()} disabled={props.currentQuestionIndex<1}>
          <i className="chevron up icon"></i>
        </button>
        <button className="ui icon button" onClick={e => props.goToNextQuestion()} disabled={!props.canGoToNextQuestion || !(props.currentQuestionIndex<props.numQuestions)}>
          <i className="chevron down icon"></i>
        </button>
      </span>

      {props.children}

      <span>
        <button className="ui icon button" onClick={e => props.back()}>
          <i className="chevron left icon"></i>
        </button>
      </span>
    </BottomBar>
  )
}

export default withScrollFormQuestionContext(ScrollFormBottomBar)

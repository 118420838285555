import React, { useCallback, useMemo, useState } from "react"
import { useRecoilState } from 'recoil'

import Modal from "Components/Modal/Modal"
import ScrollForm, { ScrollFormContext } from "Components/ScrollForm/ScrollForm"
import ScrollFormBottomBar from "Components/ScrollForm/ScrollFormBottomBar"
import ScrollFormInput from "Components/ScrollForm/ScrollFormInput"

import TransactionAddOns from "./TransactionAddOns/TransactionAddOns"
import TransactionOwers from "./TransactionOwers/TransactionOwers"
import TransactionPayers from "./TransactionPayers/TransactionPayers"

import groupState from 'appRecoil/group'
import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import { API, INPUT_RESTRICTIONS, SERVER_URL } from 'utils/constants'
import { AMOUNT_FACTOR } from 'utils/parseInputAmount'
import handleResponse from 'utils/handleResponse'

import './transactionModal.scss'

type Props = {
  editIndex: number,
  hide: Function,
  show: boolean,
  triggerReset: boolean,
}

const TransactionModal = (props:Props) => {
  const [group, setGroup] = useRecoilState(groupState)
  const [transaction, setTransaction] = useRecoilState(transactionState)
  const resetTransaction = useCallback(
    () => dispatchTransaction(transaction, setTransaction, {type: "reset"}),
    []
  )

  const isEditing = props.editIndex >= 0

  const submit = (resetForm: Function) => {
    const body = {
      _id: group._id,
      transaction,
    }

    if(isEditing) {
      fetch(`${SERVER_URL}${API.TRANSACTION}`, {
        method: 'PUT',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          ...body,
          transactionIndex: props.editIndex
        }),
      }).then(handleResponse).then(
        res => res.json()
      ).then(data => {
        // console.log("EDITED TRANSACTION", data)
        setGroup({
          ...group,
          ...data,
        })
        props.hide()
        resetForm()
        resetTransaction()
      }).catch(err => {
        console.error(err) //TODO
      })
    }
    else {
      fetch(`${SERVER_URL}${API.TRANSACTION}`, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(body),
      }).then(handleResponse).then(
        res => res.json()
      ).then(data => {
        // console.log("ADDED TRANSACTION", data)
        setGroup({
          ...group,
          ...data,
        })
        props.hide()
        resetForm()
        resetTransaction()
      }).catch(err => {
        console.error(err) //TODO
      })
    }
  }

  const questions = useMemo(() => {
    return [
      {
        canGoToNextQuestion: transaction.name.trim().length > 0,
        element: (
          <ScrollFormInput
            errorMessage="Please enter a name"
            isShown={props.show}
            label="Name"
            maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) =>
              dispatchTransaction(transaction, setTransaction, { type: 'name', name: e.target.value })
            }
            placeholder="Ex: Lunch, Housing, Travel"
            required
            type="text"
            value={transaction.name}
          />
        )
      },
      {
        canGoToNextQuestion: true,
        element: (
          <ScrollFormInput
            isShown={props.show}
            label="Description"
            maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
            okButtonText={transaction.description.trim().length===0 ? "Skip" : "Ok"}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) =>
              dispatchTransaction(transaction, setTransaction, { type: 'description', description: e.target.value })
            }
            type="text"
            value={transaction.description}
          />
        )},
      {
        canGoToNextQuestion: transaction.owers.length > 0,
        element: <TransactionOwers triggerReset={props.triggerReset}/>
      },
      {
        canGoToNextQuestion: true,
        element: <TransactionAddOns triggerReset={props.triggerReset}/>
      },
      // {
      //   canGoToNextQuestion: true,
      //   element: (
      //     <ScrollFormInput
      //       isShown={props.show}
      //       label="Tax (will be distributed proportionally)"
      //       max={INPUT_RESTRICTIONS.MAX.AMOUNT}
      //       min={0}
      //       okButtonText={transaction.tax > 0 ? "Ok" : "Skip"}
      //       onChange={(e:React.ChangeEvent<HTMLInputElement>) =>
      //         dispatchTransaction(transaction, setTransaction, { type: 'inputTax', inputTax: e.target.value })
      //       }
      //       type="number"
      //       value={transaction.tax / AMOUNT_FACTOR}
      //     />
      //   )
      // },
      // {
      //   canGoToNextQuestion: true,
      //   element: (
      //     <ScrollFormInput
      //       isShown={props.show}
      //       label="Tip (will be distributed proportionally)"
      //       max={INPUT_RESTRICTIONS.MAX.AMOUNT}
      //       min={0}
      //       okButtonText={transaction.tip > 0 ? "Ok" : "Skip"}
      //       onChange={(e:React.ChangeEvent<HTMLInputElement>) =>
      //         dispatchTransaction(transaction, setTransaction, { type: 'inputTip', inputTip: e.target.value })
      //       }
      //       type="number"
      //       value={transaction.tip / AMOUNT_FACTOR}
      //     />
      //   )
      // },
      {
        canGoToNextQuestion: transaction.payers.length > 0 && transaction.totalOwed === transaction.totalPaid,
        element: <TransactionPayers triggerReset={props.triggerReset}/>
      },
      {
        canGoToNextQuestion: true,
        element: (
          <ScrollFormContext.Consumer>
            {(scrollFormContext) => (
              <React.Fragment>
                <button className="ui button yellowButton" onClick={e => submit(scrollFormContext.resetForm)}>Save Transaction</button> <span>press Enter</span>
              </React.Fragment>
            )}
          </ScrollFormContext.Consumer>
        )
      },
    ]
  }, [transaction, props.triggerReset])

  return (
    <Modal hide={props.hide} show={props.show}>
      <div className="fullscreen transactionModal">
        <h3 className="fullscreenHeader">{isEditing ? "Edit" : "Add"} Transaction</h3>

        <ScrollForm questions={questions} triggerReset={props.triggerReset}>
          <ScrollFormBottomBar
            back={props.hide}
            numQuestions={questions.length}
          >
            <div className="totalOwerPaidContainer">
              <span className="totalOwed">
                <div className="label">Total Owed</div>
                <div className="amount">{transaction.totalOwed / AMOUNT_FACTOR}</div>
              </span>
              <span className="totalPaid">
                <div className="label">Total Paid</div>
                <div className="amount">{transaction.totalPaid / AMOUNT_FACTOR}</div>
              </span>
            </div>
          </ScrollFormBottomBar>
        </ScrollForm>
      </div>
    </Modal>
  )
}


export default TransactionModal

import { Validator } from 'jsonschema'

import { INPUT_RESTRICTIONS } from './constants'

export type DebtType = {
  amount: number,
  ower: string,
  payer: string,
}
export const debtSchema = {
  id: "/debtSchema",
  type: "object",
  properties: {
    amount: { type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    ower: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    payer: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
  },
  additionalProperties: false,
}



export type MemberType = {
  debtIndecies: number[],
  involved: number,
  name: string,
  totalOwed: number,
}
export const memberSchema = {
  id: "/memberSchema",
  type: "object",
  properties: {
    debtIndecies: {
      type: "array",
      items: {
        type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT,
      },
      required: true
    },
    involved: { type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    name: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    totalOwed: { type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
  },
  additionalProperties: false,
}
export const DEFAULT_MEMBER = {
  debtIndecies: [],
  involved: 0,
  name: "",
  totalOwed: 0,
}



export type OwerType = {
  amount: number,
  description: string,
  names: string[],
}
export const owerSchema = {
  id: "/owerSchema",
  type: "object",
  properties: {
    amount: { type: "number", minimum: 1, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    description: { type: "string", maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    names: {
      type: "array",
      items: {
        type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH,
      },
      minItems: 1,
      required: true
    },
  },
  additionalProperties: false,
}
export const DEFAULT_OWER = {
  amount: 0,
  description: "",
  names: [],
}



export type PayerType = {
  amount: number,
  description: string,
  name: string,
}
const payerSchema = {
  id: "/payerSchema",
  type: "object",
  properties: {
    amount: { type: "number", minimum: 1, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    description: { type: "string", maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    name: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
  },
  additionalProperties: false,
}
export const DEFAULT_PAYER = {
  amount: 0,
  description: "",
  name: "",
}



export type StepType = {
  array: number[][],
  function: string,
  parameters: number[],
}
export const stepSchema = {
  id: "/stepSchema",
  type: "object",
  properties: {
    array: {
      type: "array",
      items: {
        type: "array",
        items: {
          type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT,
        },
        minItems: 0,
        required: true
      },
      minItems: 0,
      required: true
    },
    functionName: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    parameters: {
      type: "array",
      items: {
        type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT,
      },
      minItems: 1,
      maxItems: 4,
      required: true
    },
  },
  additionalProperties: false,
}




export type AddOnType = {
  amount: number,
  name: string,
}
export const addOnSchema = {
  id: "/addOnSchema",
  type: "object",
  properties: {
    amount: {
      type: "number",
      maximum: INPUT_RESTRICTIONS.MAX.AMOUNT,
      minimum: -INPUT_RESTRICTIONS.MAX.AMOUNT,
      required: true
    },
    name: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
  },
  additionalProperties: false,
}


export type TransactionType = {
  addOns: AddOnType[],
  description: string,
  involved: string[],
  name: string,
  owers: OwerType[],
  payers: PayerType[],
  totalOwed: number,
  totalPaid: number,
}
export const transactionSchema = {
  id: "/transactionSchema",
  type: "object",
  properties: {
    addOns: {
      type: "array",
      items: {
        $ref: "/addOnSchema",
      },
      required: true
    },
    description: { type: "string", maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    involved: {
      type: "array",
      items: {
        type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH,
      },
      minItems: 1,
      required: true
    },
    name: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    owers: {
      type: "array",
      items: {
        $ref: "/owerSchema",
      },
      minItems: 1,
      required: true
    },
    payers: {
      type: "array",
      items: {
        $ref: "/payerSchema",
      },
      minItems: 1,
      required: true
    },
    totalOwed: { type: "number", minimum: 1, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    totalPaid: { type: "number", minimum: 1, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
  },
  additionalProperties: false,
}
export const DEFAULT_TRANSACTION: TransactionType = {
  addOns: [
    {name: "Tax", amount: 0},
    {name: "Tip", amount: 0},
    {name: "Discounts", amount: 0},
  ],
  description: "",
  involved: [],
  name: "",
  owers: [],
  payers: [],
  totalOwed: 0,
  totalPaid: 0,
}


type RawGroupType = {
  accessCode: string,
  createdAt: number,
  debtArray: number[][],
  debtCounts:{
    complexAmount: number,
    complexCount: number,
    simplifiedAmount: number,
    simplifiedCount: number,
  },
  debts: DebtType[],
  description: string,
  lastEdited: number,
  members: MemberType[],
  name: string,
  preUrl: string,
  steps: StepType[],
  transactions: TransactionType[],
  url: string,
  userId: string,
}

export type GroupType = RawGroupType & {
  _id: string,
}

export const groupSchema = {
  id: "/groupSchema",
  type: "object",
  properties: {
    accessCode: { type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    createdAt: { type: "number", minimum: 1, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    debtArray: {
      type: "array",
      items: {
        type: "array",
        items: {
          type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT,
        },
        minItems: 0,
        required: true
      },
      minItems: 0,
      required: true
    },
    debtCounts: {
      type: "object",
      properties: {
        amountText: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
        complexAmount: { type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
        complexCount: { type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
        countText: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
        simplifiedAmount: { type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
        simplifiedCount: { type: "number", minimum: 0, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
      }
    },
    debts: {
      type: "array",
      items: {
        $ref: "/debtSchema",
      },
      minItems: 1,
      required: true
    },
    description: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    lastEdited: { type: "number", minimum: 1, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    members: {
      type: "array",
      items: {
        $ref: "/memberSchema",
      },
      minItems: 1,
      required: true
    },
    name: { type: "string", minLength: 1, maxLength: INPUT_RESTRICTIONS.MAX.LENGTH, required: true },
    steps: {
      type: "array",
      items: {
        $ref: "/stepSchema",
      },
      minItems: 1,
      required: true
    },
    transactions: {
      type: "array",
      items: {
        $ref: "/transactionSchema",
      },
      minItems: 1,
      required: true
    },
    url: { type: "number", minimum: 1, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
    userId: { type: "number", minimum: 1, maximum: INPUT_RESTRICTIONS.MAX.AMOUNT, required: true },
  },
  additionalProperties: false,
}

export const groupValidator = new Validator()
groupValidator.addSchema(addOnSchema, '/addOnSchema')
groupValidator.addSchema(debtSchema, '/debtSchema')
groupValidator.addSchema(memberSchema, '/memberSchema')
groupValidator.addSchema(owerSchema, '/owerSchema')
groupValidator.addSchema(payerSchema, '/payerSchema')
groupValidator.addSchema(stepSchema, '/stepSchema')
groupValidator.addSchema(transactionSchema, '/transactionSchema')

export const RAW_GROUP:RawGroupType = {
  accessCode:"",
  createdAt:new Date().getTime(),
  debtArray:[],
  debtCounts:{
    complexAmount:0,
    complexCount:0,
    simplifiedAmount:0,
    simplifiedCount:0,
  },
  debts:[],
  description:"",
  lastEdited:new Date().getTime(),
  members:[],
  name:"",
  preUrl: "",
  steps: [],
  transactions:[],
  url:"",
  userId:"",
}

import React, { useMemo, useState } from "react"
import { useRecoilState } from 'recoil'
import { Input } from 'semantic-ui-react'

import BottomBar from "Components/BottomBar/BottomBar"
import Modal from "Components/Modal/Modal"
import ScrollForm, { ScrollFormContext } from "Components/ScrollForm/ScrollForm"
import ScrollFormOkButton from "Components/ScrollForm/ScrollFormOkButton"

import groupState from 'appRecoil/group'
import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import { AMOUNT_FACTOR } from 'utils/parseInputAmount'
import { INPUT_RESTRICTIONS } from 'utils/constants'
import type { TransactionType } from 'utils/group'

import AddOwersForm from "./AddOwersForm/AddOwersForm"


type Props = {
  triggerReset: boolean,
}

const TransactionOwers = (props: Props) => {
  const [group, setGroup] = useRecoilState(groupState)
  const [transaction, setTransaction] = useRecoilState(transactionState)

  const [showOwersModal, setShowOwersModal] = useState<boolean>(false)
  const closeModal = () => setShowOwersModal(false)

  const hasTransactions = transaction.owers.length > 0

  return (
    <ScrollFormContext.Consumer>
      {(scrollFormContext) => (
        <React.Fragment>
          <h1 className="ui dividing header">Transaction Items</h1>
          <Modal hide={closeModal} show={showOwersModal}>
            <AddOwersForm hide={closeModal} isShown={showOwersModal} triggerReset={props.triggerReset}/>
          </Modal>

          <div className="owersPayersGrid">
            {
              hasTransactions
              ? (
                <React.Fragment>
                  <div className="ui grid">
                    <div className="row">
                      <div className="five wide column"><b>Names</b></div>
                      <div className="five wide column"><b>Description</b></div>
                      <div className="four wide column"><b>Amount</b></div>
                      <div className="two wide column"></div>
                    </div>

                    {transaction.owers.map((ower, owerIndex) =>
                      <div key={owerIndex} className="row">
                        <div className="five wide column">
                          {ower.description}
                        </div>

                        <div className="five wide column">
                          {ower.names.join(" / ")}
                        </div>

                        <div className="four wide column">
                          {ower.amount / AMOUNT_FACTOR}
                        </div>

                        <div className="two wide column">
                          <span onClick={e => dispatchTransaction(transaction, setTransaction, {type:"removeOwer", owerIndex})}><i className="remove icon"></i></span>
                        </div>
                      </div>
                    )}
                  </div>

                </React.Fragment>
              ) : <div><b>You have not added any items</b></div>
            }
          </div>

          <br/>

          <div>
            <button className="ui right labeled icon button pinkButton" onClick={() => setShowOwersModal(true)}>
              Add {hasTransactions && "More"} Items
              <i className="plus icon"></i>
            </button>
          </div>

          <br/>

          {hasTransactions && (
            <ScrollFormOkButton/>
          )}
        </React.Fragment>
      )}
    </ScrollFormContext.Consumer>
  )
}

export default TransactionOwers

import React, { useMemo, useState } from "react"
import { useRecoilState, useRecoilValue } from 'recoil'
import { useHistory } from "react-router-dom"
import { Input } from 'semantic-ui-react'

import BottomBar from "Components/BottomBar/BottomBar"
import Modal from "Components/Modal/Modal"
import RedirectMyGroups from "Containers/Groups/RedirectMyGroups"

import groupState from 'appRecoil/group'
import jwtState from 'appRecoil/jwt'

import { API, INPUT_RESTRICTIONS, ROUTES, SERVER_URL } from 'utils/constants'
import getFetchOptions from 'utils/getFetchOptions'
import { RAW_GROUP } from 'utils/group'
import type { GroupType, MemberType } from 'utils/group'
import handleResponse from 'utils/handleResponse'
import setDocumentTitle from 'utils/setDocumentTitle'

const Members = () => {
  const history = useHistory()

  const [group, setGroup] = useRecoilState(groupState)
  const jwt = useRecoilValue(jwtState)
  setDocumentTitle(`Edit Members ${group.name}`)

  const [addMemberName, setAddMemberName] = useState<string>("")
  const [addMemberNameErr, setAddMemberNameErr] = useState<boolean>(false)
  const [editMemberIndex, setEditMemberIndex] = useState<number>(-1)
  const [editMemberName, setEditMemberName] = useState<string>("")
  const [editMemberNameErr, setEditMemberNameErr] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])

  const cancelEdit = () => setEditMemberIndex(-1)


  const getMembers = () => {
    if(group.members.length > 0) {
      return (
        <React.Fragment>
          <br/>
          <div className="ui equal width grid">
            <div className="row">
              <div className="eight wide column">
                <strong>Name</strong>
              </div>
              <div className="five wide column" style={{textAlign: "center"}}>
                <strong>Transactions</strong>
              </div>
              <div className="three wide column rightColumn">
              </div>
            </div>
          </div>

          <div style={{marginTop: "0.5em", borderBottom: "1px solid rgba(34, 36, 38, 0.15)"}}></div>
          <br/>

          <div className="ui equal width grid">
            {group.members.map((m,i) =>
              <OneMember
                key={i}

                deleteMember={() => deleteMember(i)}
                member={m}
                setEditMember={() => setEditMemberIndex(i)}
              />
            )}
          </div>
        </React.Fragment>
      )
    }

    return (<div style={{textAlign: "center"}}><b>You have not added any members</b></div>)
  }


  const addNewMember = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if(group) {
      const formErrors:string[] = []
      setAddMemberNameErr(false)

      const submitAddMemberName = addMemberName.trim()

      //form validation
      if(submitAddMemberName.trim().length === 0) { //this checks for empty input
        formErrors.push("Please enter a meaningful name")
        setAddMemberNameErr(true)
      }
      if(submitAddMemberName.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
        formErrors.push(`Name must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`)
        setAddMemberNameErr(true)
      }
      if(group.members.find(m => m.name === submitAddMemberName)) {
        formErrors.push(`A member with this name already exists`)
        setAddMemberNameErr(true)
      }


      if(formErrors.length === 0) {
        const body = {
          _id: group._id,
          name: submitAddMemberName,
        }

        fetch(`${SERVER_URL}${API.MEMBER}`, {
          ...getFetchOptions(jwt),
          method: 'POST',
          body: JSON.stringify(body),
        }).then(handleResponse).then(
          res => res.json()
        ).then(data => {
          // console.log("ADDED MEMBER", data)
          setAddMemberName("") //clear the input
          setGroup({
            ...group,
            ...data,
          })
        }).catch(err => {
          setErrors([err.message]) //TODO
        })
      }
      else {
        setErrors(formErrors) //TODO
      }
    }
  }

  const renameMember = () => {
    if(group) {
      const formErrors:string[] = []
      setEditMemberNameErr(false)

      const submitEditMemberName = editMemberName.trim()

      //form validation
      if(submitEditMemberName.trim().length === 0) { //this checks for empty input
        formErrors.push("Please enter a meaningful name")
        setEditMemberNameErr(true)
      }
      if(submitEditMemberName.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
        formErrors.push(`Name must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`)
        setEditMemberNameErr(true)
      }
      if(group.members.find((m,i) => m.name===submitEditMemberName && i!==editMemberIndex)) {
        formErrors.push(`A member with this name already exists`)
        setEditMemberNameErr(true)
      }
      if(group.members[editMemberIndex] === undefined) {
        formErrors.push(`This member does not exist`)
        setEditMemberNameErr(true)
      }


      if(formErrors.length === 0) {
        const body = {
          _id: group._id,
          memberIndex: editMemberIndex,
          name: submitEditMemberName,
        }

        fetch(`${SERVER_URL}${API.MEMBER}`, {
          ...getFetchOptions(jwt),
          method: 'PUT',
          body: JSON.stringify(body),
        }).then(handleResponse).then(
          res => res.json()
        ).then(data => {
          // console.log("EDITED MEMBER", data)
          cancelEdit()
          setEditMemberName("") //clear the input
          setGroup({
            ...group,
            ...data,
          })
        }).catch(err => {
          setErrors([err.message])
        })
      }
      else {
        setErrors(formErrors) //TODO
      }
    }
  }

  const deleteMember = (memberIndex: number) => {
    if(group) {
      const formErrors:string[] = []
      if(group.members[memberIndex] === undefined) {
        formErrors.push("Member does not exist")
      }
      if(group.members[memberIndex].involved > 0) {
        formErrors.push("Member is involved in transactions and cannot be deleted")
      }


      if(formErrors.length === 0) {
        const body = {
          _id: group._id,
          memberIndex,
        }

        fetch(`${SERVER_URL}${API.MEMBER}`, {
          ...getFetchOptions(jwt),
          method: 'DELETE',
          body: JSON.stringify(body),
        }).then(handleResponse).then(
          res => res.json()
        ).then(data => {
          // console.log("DELETED MEMBER", data)
          setGroup({
            ...group,
            ...data,
          })
        }).catch(err => {
          setErrors([err.message])
        })
      }
      else {
        setErrors(formErrors) //TODO
      }
    }
  }

  const getContent = () => {
    if(group._id === "") return <RedirectMyGroups/>

    return (
      <React.Fragment>
        <div className="ui container">
          <div>
            <form className="ui form" onSubmit={addNewMember}>
              <h1 className="ui dividing header" style={{textAlign: "center"}}>Add Group Members</h1>

              <div className="ui fluid action input">
                <input
                  type="text"
                  placeholder="Group members"
                  maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
                  value={addMemberName}
                  onChange={e => setAddMemberName(e.target.value)}
                />
                <button className="ui icon button pinkButton">
                  <i className="plus icon"></i>
                </button>
              </div>

              <div className="ui hidden error message"><ul className="list"></ul></div>
            </form>
          </div>

          <br/>

          <div>
            {getMembers()}
          </div>
        </div>

        {
          group && group.members[editMemberIndex]
          ? (
            <Modal
              hide={cancelEdit}
              show
            >
              <Modal.Header>Rename {group.members[editMemberIndex].name}</Modal.Header>
              <Modal.Content>
                <Input
                  maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
                  onChange={e => setEditMemberName(e.target.value)}
                  placeholder="New Name"
                  style={{width: "100%"}}
                  type="text"
                  value={editMemberName}
                />
              </Modal.Content>
              <Modal.Footer>
                <button className="pinkButton ui button" onClick={e => renameMember()}>Rename</button>
              </Modal.Footer>
            </Modal>
          ) : null
        }

        <BottomBar>
          <button className="ui right labeled icon button yellowButton" disabled={group.members.length === 0} onClick={e => history.push(ROUTES.TRANSACTIONS)}>
            Transactions
            <i className="right chevron icon"></i>
          </button>

          <button className="ui icon button" onClick={e => history.goBack()}>
            <i className="left chevron icon"></i>
          </button>
        </BottomBar>
      </React.Fragment>
    )
  }


  return (
    <div className="newGroupBackground">
      {getContent()}
    </div>
  )
}

export default Members


type OneMemberProps = {
  deleteMember: Function,
  member: MemberType,
  setEditMember: Function,
}

const OneMember = (props: OneMemberProps) => {
  const { deleteMember, member, setEditMember } = props

  return (
    <div className="row groupMember">
      <div className="eight wide column"><div onClick={e => setEditMember()}>{member.name}</div></div>
      <div className="five wide column"><div style={{textAlign: "center"}}>{member.involved}</div></div>
      <div className="three wide column rightColumn" onClick={e => deleteMember()}>
        <i className="remove icon"></i>
      </div>
    </div>
  )
}

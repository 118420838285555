import React, { useMemo, useState } from 'react'

import type { GroupType, StepType } from 'utils/group'

import stepDrawFunctions, { DrawOutputType } from "./stepDrawFunctions"

import Steps from "./Steps"

type Props = {
  group: GroupType,
}

const StepsContainer = (props: Props) => {
  const { group } = props

  const [index, setIndex] = useState<number>(0)
  const [width, setWidth] = useState<number>(0)

  //get meaningful steps to draw
  const steps = useMemo(() => {
    const lastIndex = group.steps.length - 1
    return group.steps.filter((s,i) =>
      stepDrawFunctions[s.function]!==undefined || i===lastIndex
    )
  }, [group.steps])
  const currentStep = steps[index]

  const changeStep = (increment: number) => {
    const maxLength = steps.length-2 //minus 2 because the last step is just for reference

    setIndex(Math.max(0, Math.min(maxLength, index + increment)))
  }

  const sizingData = useMemo(
    () => ({
      circleRadius: width/20,
      fontSize: width*4/345 + 8.52,
      height: 2*width/3,
      strokeWidth: width/100,
      textPadding: width/40,
    }),
    [width]
  )
  const {
    circleRadius,
    height,
    strokeWidth,
    textPadding,
  } = sizingData

  let drawOutputData: DrawOutputType = {
    before:{title:"",lines:[],texts:[],circles:[], easyText:{line1:"", line2:"", main:""}},
    after:{title:"",lines:[],texts:[],circles:[], easyText:{line1:"", line2:"", main:""}}
  };
  let easyText = {
    before: {line1:"", line2:"", main:""},
    after: {line1:"", line2:"", main:""},
  }
  let stepTitle = ""

  //if there are steps to draw
  if(steps.length > 1) {
    const names = group.members.map((m) => m.name)
    //determine title
    if(currentStep !== undefined) {
      stepTitle = `Step - ${index+1}/${steps.length-1}`

      if(currentStep.function === "mutualDebt") {
        stepTitle += " - Mutual Debt";
      }
      else if(currentStep.function === "diamondDebt") {
        stepTitle += " - Debt Diamond"
      }
      else {
        stepTitle += " - Debt Triangle"
      }
    }

    //run the respective draw function
    const drawFunction = stepDrawFunctions[ currentStep.function ]
    if(drawFunction) {
      drawOutputData = drawFunction({
        circleRadius,
        height,
        index,
        names,
        parameters: currentStep.parameters,
        steps,
        strokeWidth,
        textPadding,
        width,
      })
    }
  }
  else {
    stepTitle = "Easy peasy!"

    easyText.before.line1 = "Debtdelta did not have to do"
    easyText.before.line2 = "any meaningful calculations!"
    easyText.after.main = "✔"
  }


  return (
    <Steps
      changeStep={changeStep}
      currentStep={currentStep}
      drawOutputData={drawOutputData}
      easyText={easyText}
      index={index}
      setWidth={setWidth}
      sizingData={sizingData}
      steps={steps}
      stepTitle={stepTitle}
      width={width}
    />
  );
}

export default StepsContainer


export type StepsProps = {
  changeStep: (increment: number) => void,
  currentStep: StepType,
  drawOutputData: DrawOutputType,
  easyText: {
    before: {line1: string, line2: string, main: string},
    after: {line1: string, line2: string, main: string},
  },
  index: number,
  setWidth: (width: number) => void,
  sizingData: {
    circleRadius: number,
    fontSize: number,
    height: number,
    strokeWidth: number,
    textPadding: number,
  },
  steps: StepType[],
  stepTitle: string,
  width: number,
}

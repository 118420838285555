import React, { useCallback, useContext, useState } from "react"
import { useRecoilState } from 'recoil'

import Input from "Components/Input/Input"

import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import { INPUT_RESTRICTIONS } from 'utils/constants'

import "./transactionAddOns.scss"

const TransactionAddOnForm = () => {
  const [transaction, setTransaction] = useRecoilState(transactionState)

  const [newAmount, setNewAmount] = useState<string>("")
  const [newAmountError, setNewAmountError] = useState<boolean>(false)
  const [newName, setNewName] = useState<string>("")
  const [newNameError, setNewNameError] = useState<boolean>(false)

  const amountIsInvalid = useCallback((amount: string) => isNaN(parseFloat(amount)), [])
  const nameIsInvalid = useCallback((name: string) => name.trim() === "", [])

  const addNewAddOn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    let error = false
    setNewAmountError(false)
    setNewNameError(false)

    const name = newName.trim()
    if(nameIsInvalid(name)) {
      error = true
      setNewNameError(true)
    }
    if(amountIsInvalid(newAmount)) {
      error = true
      setNewAmountError(true)
    }
    
    if(!error) {
      dispatchTransaction(transaction, setTransaction, {type:"addAddOn", inputAmount: newAmount, name})
      setNewAmount("")
      setNewName("")
    }
  }

  return (
    <form className="row ui form" onKeyDown={e => e.stopPropagation()} onSubmit={addNewAddOn}>
      <div className="eight wide column">
        <Input data={{
          error: newNameError,
          label: "",
          placeholder: "Add On Name",
          required: false,
          rest: {
            maxLength: INPUT_RESTRICTIONS.MAX.LENGTH,
          },
          set: (val: string) => {
            setNewName(val)
            setNewNameError(nameIsInvalid(val))
          },
          value: newName,
        }}/>
      </div>
      <div className="six wide column">
        <Input data={{
          error: newAmountError,
          label: "",
          placeholder: "Amount",
          required: false,
          rest: {
            max: INPUT_RESTRICTIONS.MAX.AMOUNT,
            min: -INPUT_RESTRICTIONS.MAX.AMOUNT,
            type: "number",
          },
          set: (val: string) => {
            setNewAmount(val)
            setNewAmountError(amountIsInvalid(val))
          },
          value: newAmount,
        }}/>
      </div>
      <div className="two wide column"><button className="noStyleButton" type="submit"><i className="plus icon"></i></button></div>
    </form>
  )
}

export default TransactionAddOnForm

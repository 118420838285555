import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { Link, useHistory } from 'react-router-dom'
import { Form, Input, Tab } from 'semantic-ui-react'

import isAuthenticatedState, { AuthenticationEnum } from 'appRecoil/isAuthenticated'

import Messages from "Components/Messages/Messages"

import { API, INPUT_RESTRICTIONS, ROUTES, SERVER_URL } from 'utils/constants'
import handleResponse from 'utils/handleResponse'
import setDocumentTitle from 'utils/setDocumentTitle'

// type Props = RouteComponentProps

const Register = () => {
  setDocumentTitle("Register")

  const history = useHistory()

  const [isAuthenticated, setIsAuthenticated] = useRecoilState(isAuthenticatedState)

  const [username, setUsername] = useState<string>("")
  const [usernameErr, setUsernameErr] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [passwordErr, setPasswordErr] = useState<boolean>(false)
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [confirmPasswordErr, setConfirmPasswordErr] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>([])

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    setUsernameErr(false)
    setPasswordErr(false)
    setConfirmPasswordErr(false)
    setErrors([])

    let errors:string[] = []

    //form validation
    if(username.trim().length === 0) { //this checks for empty input
      errors.push("Please enter a valid username");
      setUsernameErr(true)
    }
    if(username.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      errors.push(`Username must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`);
      setUsernameErr(true)
    }
    if(password.length === 0) {
      errors.push(`Please enter a meaninful password`);
      setPasswordErr(true)
    }
    if(password.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      errors.push(`Password must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`);
      setPasswordErr(true)
    }
    if(password !== confirmPassword) {
      errors.push(`Passwords do not match`)
      setConfirmPasswordErr(true)
    }
    if(confirmPassword.length === 0) {
      errors.push(`Please confirm your password`)
      setConfirmPasswordErr(true)
    }
    if(confirmPassword.length > INPUT_RESTRICTIONS.MAX.LENGTH) {
      errors.push(`Password must be under ${INPUT_RESTRICTIONS.MAX.LENGTH} characters`)
      setConfirmPasswordErr(true)
    }


    //if there is no error
    if(errors.length === 0) {
      fetch(`${SERVER_URL}${API.REGISTER}`, {
        method: 'PUT', // *GET, PUT, POST, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "include",
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          username,
          password,
        })
      }).then(handleResponse).then(data => {
        setIsAuthenticated(AuthenticationEnum.authenticated)
      }).catch(err => {
        console.error(err)
        errors.push(err.message)
        setErrors(errors)
      })
    }
    else {
      setErrors(errors)
    }
  }


  return (
    <Form onSubmit={submit}>
      <br/>
      <div className="ui container">
        <h2>Register</h2>
        <Form.Field className={`field ${  usernameErr ? "error" : ""}`}>
          <label>Username</label>
          <Input
            type="text" placeholder="Username" maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
            value={username} onChange={evt => setUsername(evt.target.value)}
          />
        </Form.Field>
        <Form.Field className={`field ${  passwordErr ? "error" : ""}`}>
          <label>Password</label>
          <Input
            type="password" placeholder="Password" maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
            value={password} onChange={evt => setPassword(evt.target.value)}
          />
        </Form.Field>
        <Form.Field className={`field ${  confirmPasswordErr ? "error" : ""}`}>
          <label>Confirm Password</label>
          <Input
            type="password" placeholder="Password" maxLength={INPUT_RESTRICTIONS.MAX.LENGTH}
            value={confirmPassword} onChange={evt => setConfirmPassword(evt.target.value)}
          />
        </Form.Field>

        <br/>

        <div>
          <button className="ui button yellow" type="submit">
            Register
          </button>

          <button className="ui button" type="button" onClick={e => history.push(ROUTES.LOGIN)}>
            Login
          </button>
        </div>

        <Messages error messages={errors}/>
      </div>
    </Form>
  )
}

export default Register

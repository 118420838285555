import type { TransactionType } from "utils/group"

/**
 * this function calculates the amounts owed and paid
 * @param  transaction transaction object to recalculate for
 * @return             new transaction object (using destructure)
 */
export default function calculateTransactionAmounts(transaction: TransactionType) {
  let totalOwed: number = 0
  let totalPaid: number = 0

  transaction.owers.forEach(ower => { //for each owers
    totalOwed += ower.amount || 0
  })

  transaction.addOns.forEach(addOn => { //for each add on
    totalOwed += addOn.amount
  })

  transaction.payers.forEach(payer => { //for each payer
    totalPaid += payer.amount || 0
  })

  return {
    ...transaction,
    totalOwed,
    totalPaid,
  }
}

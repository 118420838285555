import React from "react"

import LoadingSpinner from "Components/LoadingSpinner/LoadingSpinner"

import { ViewProps } from "./ViewContainer"

import "./view.scss"

const View = (props: ViewProps) => {
  const { status } = props

  return (
    <div id="view" className="ui container">
      <div id="viewStatus" className="fullPage">
        <div>
          {
            status === "loading"
            ? (
              <div>
                <h2>Loading...</h2>
                <LoadingSpinner dark/>
              </div>
            )
            : <h2>{status}</h2>
          }
        </div>
      </div>
    </div>
  )
}

export default View

import React, { useCallback, useMemo, useState } from 'react'

import type { InputDataType } from "utils/inputData"
import Input from './Input'

type Props = {
  data: InputDataType,
}

export default function NumberInput(props: Props) {
  const { data } = props

  const [error, setError] = useState<boolean>(false)
  const [value, setValue] = useState<string>(data.value)

  const onBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    const valueIsValid = !isNaN(parseFloat(value))
    if(valueIsValid) {
      data.set(value)
    }
    else {
      setError(true)
    }
  }, [value])

  const set = useCallback((val: string) => {
    setValue(val)
    setError(false)
  }, [])
  return (
    <Input data={{
      ...data,
      error: error || data.error,
      rest: {
        ...data.rest,
        onBlur,
        type: "number"
      },
      set,
      value
    }}/>
  )
}

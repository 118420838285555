import React, { useMemo, useState } from "react"
import { useRecoilState } from 'recoil'
import { Input } from 'semantic-ui-react'

import BottomBar from "Components/BottomBar/BottomBar"
import Modal from "Components/Modal/Modal"
import ScrollForm, { ScrollFormContext, ScrollFormContextType } from "Components/ScrollForm/ScrollForm"
import ScrollFormOkButton from "Components/ScrollForm/ScrollFormOkButton"

import groupState from 'appRecoil/group'
import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import { AMOUNT_FACTOR } from 'utils/parseInputAmount'
import { INPUT_RESTRICTIONS } from 'utils/constants'
import type { TransactionType } from 'utils/group'

import AddPayerForm from "./AddPayerForm/AddPayerForm"


type Props = {
  triggerReset: boolean,
}

const TransactionPayers = (props: Props) => {
  const [group, setGroup] = useRecoilState(groupState)
  const [transaction, setTransaction] = useRecoilState(transactionState)

  const [showOwersModal, setShowOwersModal] = useState<boolean>(false)
  const closeModal = () => setShowOwersModal(false)

  const hasPayers = transaction.payers.length > 0

  const getContent = (scrollFormContext:ScrollFormContextType) => {
    if(!hasPayers) {
      return <div><b>You have not added any payers</b></div>
    }

    return (
      <div className="ui grid">
        <div className="row">
          <div className="five wide column"><b>Name</b></div>
          <div className="five wide column"><b>Description</b></div>
          <div className="four wide column"><b>Amount</b></div>
          <div className="two wide column"></div>
        </div>

        {transaction.payers.map((payer, payerIndex) =>
          <div key={payerIndex} className="row">
            <div className="five wide column">
              {payer.description}
            </div>

            <div className="five wide column">
              {payer.name}
            </div>

            <div className="four wide column">
              {payer.amount / AMOUNT_FACTOR}
            </div>

            <div className="two wide column">
              <div onClick={e => dispatchTransaction(transaction, setTransaction, {type:"removePayer", payerIndex})}><i className="remove icon"></i></div>
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <ScrollFormContext.Consumer>
      {(scrollFormContext) => (
        <React.Fragment>
          <h1 className="ui dividing header">Transaction Payers</h1>
          <Modal hide={closeModal} show={showOwersModal}>
            <AddPayerForm hide={closeModal} isShown={showOwersModal} triggerReset={props.triggerReset}/>
          </Modal>

          <div className="owersPayersGrid">{getContent(scrollFormContext)}</div>

          <br/>

          <div>
            <button className="ui right labeled icon button greenButton" onClick={() => setShowOwersModal(true)}>
              Add {hasPayers && "More"} Payers
              <i className="plus icon"></i>
            </button>
          </div>

          <br/>

          {hasPayers&& (
            transaction.totalOwed !== transaction.totalPaid
            ? <div><b style={{color: "var(--darkRed)"}}>Total Owed must match Total Paid</b></div>
            : <ScrollFormOkButton/>
          )}
        </React.Fragment>
      )}
    </ScrollFormContext.Consumer>
  )
}

export default TransactionPayers

import React from "react"

import { ScrollFormContext } from "Components/ScrollForm/ScrollForm"

type Props = {
  buttonText: string,
}

const ScrollFormOkButton = (props: Props) => {
  return (
    <ScrollFormContext.Consumer>
      {(scrollFormContext) => (
        <div>
          <button className="ui button" onClick={e => scrollFormContext.goToNextQuestion()}>{props.buttonText}</button> <span>press Enter</span>
        </div>
      )}
    </ScrollFormContext.Consumer>
  )
}

ScrollFormOkButton.defaultProps = {
  buttonText: "Ok"
}

export default ScrollFormOkButton

export const SERVER_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:5000"

export const INPUT_RESTRICTIONS = {
  MAX: {
    LENGTH: 1000,
    AMOUNT: 99999999999,
  }
}

export const API = {
  CALCULATE: "/calculate",
  EDIT: "/edit",
  GROUP: "/group",
  GROUPS: "/groups",
  IS_AUTHENTICATED: "/isAuthenticated",
  LANDING: "/",
  LOGIN: "/login",
  MEMBER: "/member",
  REGISTER: "/register",
  TRANSACTION: "/transaction",
  VIEW: "/view",
}

export const ROUTES = {
  ACCOUNT: "/account",
  INFO: "/info",
  MEMBERS: "/members",
  TRANSACTIONS: "/transactions",
  LANDING: "/",
  LOGIN: "/login",
  MY_GROUPS: "/my-groups",
  REGISTER: "/register",
  VIEW: "/view",
  SEARCH: "/search", //mobile only right now
}

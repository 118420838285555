import React from 'react'
import { Button, Dropdown, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import { ROUTES } from "utils/constants"

import { FEEDBACK_FORM_URL, NavBarProps } from "./NavBarContainer"

import "./navBar.scss"


const NavBar = (props:NavBarProps) => {
  const {
    isAuthenticated,
    logout: parentLogout,
    username,
  } = props

  function logout() {
    document.cookie = "jwt=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
    document.cookie = "jwt=;domain=debtdelta.com;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
    document.cookie = "jwt=;domain=.debtdelta.com;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/"
    parentLogout()
  }


  const loggedInDropdownOptions = [
    // {
    //   key: 'account',
    //   text: (
    //     <span onClick={() => props.history.push(ROUTES.ACCOUNT)}>
    //       Account TODO
    //     </span>
    //   ),
    // },
    {
      key: 'myGroups',
      text: (
        <Link to={ROUTES.MY_GROUPS}>My Groups</Link>
      ),
    },
    {
      key: 'feedback',
      text: (
        <a href={FEEDBACK_FORM_URL} target="_blank" rel="noopener noreferrer">Feedback</a>
      ),
    },
    {
      key: 'logOut',
      text: (
        <span onClick={logout}>
          Log Out
        </span>
      ),
    }
  ]

  const content = (
    isAuthenticated > 0
    ? <Dropdown trigger={<span>{username}</span>} options={loggedInDropdownOptions} direction='left'/>
    : <Link to={ROUTES.LOGIN}><Button basic>Log In</Button></Link>
  )


  return (
    <div id="navBar">
      <div id="navBarContainer" className="ui container">
        <Link id="logo" to={ROUTES.LANDING}>
          <img src="/img/debtTriangle.svg" alt="logo"/>
          <div id="logoText">Debt Delta</div>
        </Link>
        <div>
          {content}
        </div>
      </div>
    </div>
  )
}


export default NavBar

import React, { useEffect, useState } from 'react'

import WaitForServer from "./WaitForServer"

export default function WaitForServerContainer() {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    const timeout = window.setTimeout( //set a timeout to show content
      () => setShow(true), 2000
    )
    return () => {
      clearTimeout(timeout) //clear the timeout in case the server responds in time
    }
  })

  if(show) {
    return <WaitForServer/>
  }

  return null
}

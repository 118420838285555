import binaryInsert from 'utils/binaryInsert'
import type { TransactionType } from "utils/group"

/**
 * this function recalculates the involved names in a transaction, and returns a new transaction object
 * @param  transaction transaction object to recalculate involved for
 * @return             new transaction object (using destructure) with new involved
 */
export default function calculateTransactionInvolved(transaction: TransactionType) {
  const involved: string[] = []

  transaction.owers.forEach(ower => { //for each owers
    ower.names.forEach(name => { //for each name involved as an ower
      binaryInsert(involved, name) //insert the name
    })
  })

  transaction.payers.forEach(payer => { //for each payer
    binaryInsert(involved, payer.name) //insert the name
  })

  return {
    ...transaction,
    involved,
  }
}

import React from "react"
import { useRecoilState } from 'recoil'

import { ScrollFormContext } from "Components/ScrollForm/ScrollForm"
import ScrollFormOkButton from "Components/ScrollForm/ScrollFormOkButton"

import transactionState, { dispatchTransaction } from 'appRecoil/transaction'

import { AMOUNT_FACTOR } from 'utils/parseInputAmount'

import "./transactionAddOns.scss"
import NumberInput from "Components/Input/NumberInput"
import TransactionAddOnForm from "./TransactionsAddOnForm"

type Props = {
  triggerReset: boolean,
}

const TransactionAddOns = (props: Props) => {
  const [transaction, setTransaction] = useRecoilState(transactionState)
  const { addOns } = transaction

  return (
    <ScrollFormContext.Consumer>
      {(scrollFormContext) => (
        <React.Fragment>
          <h1 className="ui dividing header">Transaction Add Ons</h1>
          <p>(Tax, Tip, Discounts, etc to be distributed proportionally)</p>

          <div className="ui container">
            <div className="ui grid addOnGrid">
              <div className="row">
                <div className="eight wide column"><b>Name</b></div>
                <div className="six wide column"><b>Amount</b></div>
                <div className="two wide column"></div>
              </div>

              <TransactionAddOnForm/>

              <div className="row">
                <div className="two wide column"></div>
                <div className="twelve wide column" style={{borderBottom: "1px solid #ddd"}}></div>
                <div className="two wide column"></div>
              </div>

              {addOns.map((a, i) =>
                <div key={i} className="row">
                  <div className="eight wide column">
                    {a.name}
                  </div>

                  <div className="six wide column ui form">{
                    <NumberInput data={{
                      error: false,
                      label: "",
                      placeholder: "Amount",
                      required: false,
                      rest: {
                        type: "number",
                      },
                      set: (value: string) => dispatchTransaction(transaction, setTransaction, {type:"editAddOn", addOnIndex: i, inputAmount: value}),
                      value: (a.amount / AMOUNT_FACTOR).toString(),
                    }}/>
                  }</div>

                  <div className="two wide column">
                    <span onClick={e => dispatchTransaction(transaction, setTransaction, {type:"removeAddOn", addOnIndex: i})}><i className="remove icon"></i></span>
                  </div>
                </div>
              )}
            </div>

            <br/>

             <div className="row"><div className="sixteen wide column"><hr/></div></div>
          </div>

          <br/>
          <br/>

          <ScrollFormOkButton/>
        </React.Fragment>
      )}
    </ScrollFormContext.Consumer>
  )
}

export default TransactionAddOns

import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState  } from 'react'
import Messages from "Components/Messages/Messages"

import type { ScrollFormQuestionContextType } from "Components/ScrollForm/ScrollForm"
import { withScrollFormQuestionContext } from "Components/ScrollForm/ScrollForm"
import ScrollFormOkButton from "Components/ScrollForm/ScrollFormOkButton"

type Props = ScrollFormQuestionContextType & {
  errorMessage: React.ReactNode,
  label: React.ReactNode,
  isShown: boolean,
  max?: number,
  maxLength?: number,
  min?: number,
  placeholder?: string,
  okButtonText?:string,
  okMessage: React.ReactNode,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  required?: boolean,
  subHeading?: JSX.Element,
  type: "string" | "number" | "password" | "email",
  value: number | string,
}

const ScrollFormInput = (props: Props) => {
  const [shouldAutoFocus, setShouldAutoFocus] = useState<boolean>(true)
  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if(shouldAutoFocus && props.focused && props.isShown && ref.current) {
      ref.current.focus()
      setShouldAutoFocus(false)
    }
    else if(shouldAutoFocus === false) {
      setShouldAutoFocus(true)
    }
  }, [props.focused, props.isShown])

  return (
    <div className="ui form">
      <div className={`field${props.required===true?" required":""}`}><label>{props.label}</label></div>
      {props.subHeading}
      <div className="ui input">
        <input
          onBlur={e => props.markDirty()}
          max={props.max}
          maxLength={props.maxLength}
          min={props.min}
          onChange={props.onChange}
          placeholder={props.placeholder}
          ref={ref}
          type={props.type}
          value={props.value}
        />
      </div>
      <br/><br/>
      <div className="formMessageContainer">
        <div className="formMessage" style={{opacity: props.canGoToNextQuestion ? 1 : 0}}>
          {props.okMessage || <ScrollFormOkButton buttonText={props.okButtonText}/>}
        </div>

        <div className="formMessage" style={{display: props.focused && !props.canGoToNextQuestion && props.dirty ? "" : "none"}}>
          <Messages error slow>{props.errorMessage}</Messages>
        </div>
      </div>
    </div>
  )
}

ScrollFormInput.defaultProps = {
  isShown: true,
}

export default withScrollFormQuestionContext(ScrollFormInput)

import React, { useMemo, useState } from "react"
import { timeFormat } from 'd3'
import { Form, Input, Tab } from 'semantic-ui-react'
import { useHistory } from "react-router-dom"

import Modal from "Components/Modal/Modal"

import { INPUT_RESTRICTIONS, ROUTES } from 'utils/constants'
import setDocumentTitle from 'utils/setDocumentTitle'

import type { GroupsProps } from "./GroupsContainer"

import "./groups.scss"

const formatTime = timeFormat("%m/%d/%y %H:%M")

const Groups = (props: GroupsProps) => {
  setDocumentTitle("My Groups")

  const history = useHistory()

  const {
    completedGroups,
    deleteDraft,
    draftGroups,
    groupToDelete,
    onClickNewGroup,
    resetDeleteDraftGroup,
    setDeleteDraftGroupIndex,
    setGroup,
  } = props


  const getCompletedGroups = () => {
    if(completedGroups.length > 0) {
      return (
        <div className="ui grid">
          <div className="row">
            <div className="six wide column"><strong>Name</strong></div>
            <div className="five wide column"><strong>Access Code</strong></div>
            <div className="five wide column"><strong>Last Edited</strong></div>
          </div>

          {completedGroups.map(g =>
            <div key={g._id} className="row groupRow completedGroup" onClick={e => history.push(`${ROUTES.VIEW}/${encodeURIComponent(g.url)}`)}>
              <div className="six wide column">{g.name}</div>
              <div className="five wide column">{g.accessCode}</div>
              <div className="five wide column">{formatTime(new Date(g.lastEdited))}</div>
            </div>
          )}
        </div>
      )
    }

    return (<div className="ui grid"><strong className="sixteen wide column" style={{textAlign:"center"}}>You have no completed groups</strong></div>)
  }

  const getDraftGroups = () => {
    if(draftGroups.length > 0) {
      return (
        <div className="ui grid">
          {
            groupToDelete !== undefined
            && (
              <Modal hide={resetDeleteDraftGroup} show>
                <Modal.Header>Are you sure you want to delete group {groupToDelete.name}?</Modal.Header>
                <Modal.Footer>
                  <button className="ui button" style={{float: "left"}} onClick={e => resetDeleteDraftGroup()}>Cancel</button>
                  <button className="ui red button" onClick={e => deleteDraft()}>Delete</button>
                </Modal.Footer>
              </Modal>
            )
          }


          <div className="row">
            <div className="two wide column"></div>
            <div className="six wide column"><b>Name</b></div>
            <div className="six wide column"><b>Last Edited</b></div>
            <div className="two wide column"></div>
          </div>

          {draftGroups.map((g,i) =>
            <div key={g._id} className="row groupRow draftGroup">
              <div className="two wide column">
                <span onClick={e => {
                  setGroup(g)
                  history.push(`${ROUTES.INFO}`)
                }}>
                  <i className="edit icon"></i>
                </span>
              </div>
              <div className="six wide column overflow">{g.name}</div>
              <div className="six wide column">{formatTime(new Date(g.lastEdited))}</div>
              <div className="two wide column">
                <span onClick={e => setDeleteDraftGroupIndex(i)} style={{float: "right"}}>
                  <i className="remove icon"></i>
                </span>
              </div>
            </div>
          )}
        </div>
      )
    }

    return (<div className="ui grid"><strong className="sixteen wide column" style={{textAlign:"center"}}>You have no draft groups</strong></div>)
  }

  const panes = [
    { menuItem: `Completed (${completedGroups.length})`, render: () => <Tab.Pane>{getCompletedGroups()}</Tab.Pane> },
    { menuItem: `Drafts (${draftGroups.length})`, render: () => <Tab.Pane>{getDraftGroups()}</Tab.Pane> },
  ]

  return (
    <div id="groups" className="ui container">
      <div>
        <button className="ui right labeled teal icon button" style={{float:"right"}} onClick={e => onClickNewGroup(history)}>
          New Group
          <i className="asterisk icon"></i>
        </button>

        <h1 className="ui dividing header">My Groups</h1>
      </div>

      <br/>

      <Tab panes={panes}/>
    </div>
  )
}

export default Groups

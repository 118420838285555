import { atom } from 'recoil'

import { RAW_GROUP } from 'utils/group'
import type { GroupType } from 'utils/group'


const init = () => ({
  ...JSON.parse(JSON.stringify(RAW_GROUP)),
  _id: ""
})
const groupState = atom<GroupType>({
  key: 'group',
  default: init(),
})

export default groupState

type ActionType =
| { type: 'name', name: string }
| { type: 'reset' }

export const dispatchGroup = (
  group:GroupType,
  setGroup: (group: GroupType) => void,
  action:ActionType,
) => {
  setGroup(
    (():GroupType => {
      switch (action.type) {
        case 'name':
          return { ...group, name: action.name }
        case 'reset':
          return init()
        default:
          throw new Error(`Unexpected action`)
      }
    })()
  )
}

import { atom } from 'recoil'

import { DEFAULT_TRANSACTION } from 'utils/group'
import type { OwerType, PayerType, TransactionType } from 'utils/group'
import parseInputAmount from 'utils/parseInputAmount'
import pushElementToArrayCopy from 'utils/pushElementToArrayCopy'
import calculateTransactionAmounts from 'utils/calculateTransactionAmounts'
import calculateTransactionInvolved from 'utils/calculateTransactionInvolved'
import deleteElementFromArrayCopy from 'utils/deleteElementFromArrayCopy'

const init = () => ({
  ...JSON.parse(JSON.stringify(DEFAULT_TRANSACTION)),
  // name: "test",
  // owers: [{
  //   amount: 100,
  //   description: "test",
  //   names: ["testing"],
  // }],
  // payers: [{
  //   amount: 100,
  //   description: "test",
  //   name: "testing",
  // }],
  // totalOwed: 100,
  // totalPaid: 100,
  // involved: ["testing"]
})

const transactionState = atom<TransactionType>({
  key: 'transaction',
  default: init(),
})

export default transactionState

type ActionType =
| { type: 'addAddOn', name: string, inputAmount: string }
| { type: 'addOwer', ower: OwerType }
| { type: 'addPayer', payer: PayerType }
| { type: 'description', description: string }
| { type: 'editAddOn', addOnIndex: number, inputAmount: string }
| { type: 'name', name: string }
| { type: 'removeAddOn', addOnIndex: number }
| { type: 'removeOwer', owerIndex: number }
| { type: 'removePayer', payerIndex: number }
| { type: 'reset' }

export const dispatchTransaction = (
  transaction:TransactionType,
  setTransaction: (transaction: TransactionType) => void,
  action:ActionType,
) => {
  setTransaction(
    (():TransactionType => {
      switch (action.type) {
        case 'addAddOn': {
          const addOnsCopy = transaction.addOns.slice()
          addOnsCopy.push({
            amount: parseInputAmount(action.inputAmount),
            name: action.name.trim(),
          })
          return calculateTransactionAmounts(
            {...transaction, addOns: addOnsCopy}
          )
        }
        case 'addOwer':
          return calculateTransactionAmounts(
            calculateTransactionInvolved({
              ...transaction,
              owers: pushElementToArrayCopy(transaction.owers, action.ower)
            })
          )
        case 'addPayer':
          return calculateTransactionAmounts(
            calculateTransactionInvolved({
              ...transaction,
              payers: pushElementToArrayCopy(transaction.payers, action.payer)
            })
          )
        case 'description':
          return {...transaction, description: action.description}
        case 'editAddOn': {
          const addOnsCopy = transaction.addOns.slice()
          addOnsCopy.splice(action.addOnIndex, 1, { //delete then add a new add on
            amount: parseInputAmount(action.inputAmount),
            name: addOnsCopy[action.addOnIndex].name, //use the same name
          })
          return calculateTransactionAmounts(
            {...transaction, addOns: addOnsCopy}
          )
        }
        case 'name':
          return {...transaction, name: action.name}
        case 'removeAddOn': {
          const addOnsCopy = transaction.addOns.slice()
          addOnsCopy.splice(action.addOnIndex, 1) //delete the add on
          return calculateTransactionAmounts(
            {...transaction, addOns: addOnsCopy}
          )
        }
        case 'removeOwer':
          return calculateTransactionAmounts(
            calculateTransactionInvolved({
              ...transaction,
              owers: deleteElementFromArrayCopy(transaction.owers, action.owerIndex)
            })
          )
        case 'removePayer':
          return calculateTransactionAmounts(
            calculateTransactionInvolved({
              ...transaction,
              payers: deleteElementFromArrayCopy(transaction.payers, action.payerIndex)
            })
          )
        case 'reset':
          return init()
        default:
          throw new Error(`Unexpected action`)
      }
    })()
  )
}

import React, { useEffect, useReducer, useRef, useState } from 'react'
import usePrevious from "utils/usePrevious"

import './scrollForm.scss'

export type ScrollFormContextType = {
  canGoToNextQuestion: boolean,
  currentQuestionIndex: number,
  goToQuestion: (number: number) => void,
  goToNextQuestion: () => void,
  goToPrevQuestion: () => void,
  resetForm: () => void,
}

export const ScrollFormContext = React.createContext<ScrollFormContextType>({
  canGoToNextQuestion: true,
  currentQuestionIndex: 0,
  goToQuestion: (number) => {},
  goToNextQuestion: () => {},
  goToPrevQuestion: () => {},
  resetForm: () => {},
})

export type QuestionContextType = {
  // canGoToNextQuestion: boolean,
  dirty: boolean,
  focused: boolean,
  markDirty: Function,
}

export const QuestionContext = React.createContext<QuestionContextType>({
  // canGoToNextQuestion: false,
  dirty: false,
  focused: false,
  markDirty: (index: number) => {},
})

export type ScrollFormQuestionContextType = ScrollFormContextType & QuestionContextType


type Props = {
  children: React.ReactNode,
  enterToChangeQuestion: boolean,
  goToQuestionCallback: (questionIndex: number, validQuestionIndex: boolean, resetForm: Function) => void,
  onScrollEndCallback: (questionIndex: number) => void,
  questions: {
    canGoToNextQuestion?: boolean,
    element: React.ReactNode,
  }[],
  tabToChangeQuestion: boolean,
  touchScrollThreshold: number,
  transitionSeconds: number,
  triggerReset?: boolean,
  wheelScrollThreshold: number,
}


type DisplayActionType =
| { type: 'mark', index: number }
| { type: 'reset' }

function dirtyReducer(state: boolean[], action:DisplayActionType) {
  switch (action.type) {
    case 'mark':
      if(state[action.index] !== undefined) {
        const copy = state.slice()
        copy[action.index] = true
        return copy
      }
      throw new Error(`Invalid index ${action.index}`)
    case 'reset':
      return state.map(d => false)
    default:
      throw new Error("Unexpected action")
  }
}



const ScrollForm = (props:Props) => {
  const [canScroll, setCanScroll] = useState<boolean>(true)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)
  const [dirty, dispatchDirty] = useReducer(dirtyReducer, props.questions.map(q => false))
  const [height, setHeight] = useState<number>(window.innerHeight)
  const [touchStartY, setTouchStartY] = useState<number | null>(null)

  const questionsContainerRef = useRef<HTMLDivElement>(null)

  const resetForm = () => {
    dispatchDirty({type:"reset"})
    setCurrentQuestionIndex(0)
  }

  const prevTriggerReset = usePrevious(props.triggerReset)
  useEffect(() => {
    if(props.triggerReset !== prevTriggerReset) {
      resetForm()
    }
  }, [props.triggerReset])


  const onResize = () => {
    if(questionsContainerRef?.current) {
      setHeight(questionsContainerRef?.current?.clientHeight || 500)
    }
  }

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    goToQuestion(0) //initialize to first question

    return () => window.removeEventListener('resize', onResize)
  }, [])


  const canGoToNextQuestion = props.questions[currentQuestionIndex].canGoToNextQuestion !== false


  const goToQuestion = (questionIndex: number) => {
    const validQuestionIndex = props.questions[questionIndex] !== undefined
    if(validQuestionIndex) { //if the question index is valid
      // console.log("GO TO QUESTION canGoToNextQuestion",canGoToNextQuestion)
      if((questionIndex <= currentQuestionIndex) || canGoToNextQuestion) {
        setCurrentQuestionIndex(questionIndex)

        setTimeout(props.onScrollEndCallback, props.transitionSeconds*1000, questionIndex)
      }
    }

    props.goToQuestionCallback(questionIndex,validQuestionIndex, resetForm) //run the callback
  }

  const goToPrevQuestion = () => goToQuestion(currentQuestionIndex - 1)
  const goToNextQuestion = () => goToQuestion(currentQuestionIndex + 1)



  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if(e.key==="Tab" && e.shiftKey && props.tabToChangeQuestion) { //SHIFT + TAB
      e.preventDefault()
      e.stopPropagation()
      goToPrevQuestion() //go to previous question
    }
    else if(
      (e.key==="Enter" && props.enterToChangeQuestion) || //ENTER
      (e.key==="Tab" && props.tabToChangeQuestion) //TAB
    ) {
      e.preventDefault()
      e.stopPropagation()
      goToNextQuestion() //go to next question
    }
  }


  const onWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if(Math.abs(e.deltaY) > props.wheelScrollThreshold) { //if we have exceeded the threshold
      if(canScroll) { //if we are allowed to scroll
        goToQuestion(currentQuestionIndex + Math.sign(e.deltaY))
        setCanScroll(false) //we are not allowed to scroll anymore
      }
    }
    else { //else the wheel just started
      setCanScroll(true) //we can scroll again
    }
  }

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setTouchStartY(e.touches[0].screenY) //set the starting y position
  }
  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if(typeof touchStartY === "number") { //if touch start y is still a number
      const difference = touchStartY - e.touches[0].screenY //get the pixel difference between the starting y position and current
      if(Math.abs(difference) > props.touchScrollThreshold) { //if the difference is big enough
        goToQuestion(currentQuestionIndex + Math.sign(difference))
        setTouchStartY(null) //set the start y to null so that one swipe only increments by one question
      }
    }
  }
  const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setTouchStartY(null)
  }




  const {
    questions,
    transitionSeconds,
  } = props

  return (
    <ScrollFormContext.Provider value={{
      canGoToNextQuestion,
      currentQuestionIndex,
      goToQuestion,
      goToPrevQuestion,
      goToNextQuestion,
      resetForm,
    }}>
      <div
        className="scrollForm"
        onKeyDown={onKeyDown}
        onWheel={onWheel}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        ref={questionsContainerRef}
      >
        {questions.map((q,i) =>
          <QuestionContext.Provider key={i} value={{
            dirty: dirty[i],
            focused: i === currentQuestionIndex,
            markDirty: () => {
              if(currentQuestionIndex === i) {
                dispatchDirty({type:"mark", index: i})
              }
            },
          }}>
            <div style={{
              height,
              position: "absolute",
              top: height * (i - currentQuestionIndex),
              left: 0,
              right: 0,
              opacity: i===currentQuestionIndex ? 1 : 0,
              transition: `${transitionSeconds}s`,
            }}>
              <div className="questionContainer">{q.element}</div>
            </div>
          </QuestionContext.Provider>
        )}

        {props.children}
      </div>
    </ScrollFormContext.Provider>
  )
}

ScrollForm.defaultProps = {
  enterToChangeQuestion: true,
  goToQuestionCallback: (questionIndex:number, validQuestionIndex:boolean, resetForm: Function) => {},
  onScrollEndCallback: (questionIndex:number) => {},
  tabToChangeQuestion: true,
  touchScrollThreshold: 10,
  transitionSeconds: 1,
  wheelScrollThreshold: 200,
}

export default ScrollForm

export const withScrollFormQuestionContext = (Component:React.FC<any>) => {
  return (props: any) => (
    <ScrollFormContext.Consumer>
      {(scrollFormContext) => (
        <QuestionContext.Consumer>
          {(questionContext) => (
            <Component {...props} {...scrollFormContext} {...questionContext}/>
          )}
        </QuestionContext.Consumer>
      )}
    </ScrollFormContext.Consumer>
  )
}
